import React, {useEffect} from 'react';
import PageTitle from "../Components/PageTitle";
import NavigationNavBar from '../Components/NavigationNavBar'
import {Navigation} from "../Constants/Navigations";
import './UserProfile/style/user_profile.css'
import {Switch, Route} from "react-router-dom";
import {ScrollToTop} from '../Constants/ScrollToTop'

const TermsMain = React.memo(() => {

    useEffect(() => {
        ScrollToTop()
    }, []);

    return (
        <div className={'wrapper_main_page'}>
            <PageTitle title={'Условия'}/>
            <div className={'wrapper_user_profile'}>
                <NavigationNavBar routes={Navigation.termsNavigations}/>
                <SwitcherNavBar routes={Navigation.termsNavigations}/>
            </div>
        </div>
    )
});

function SwitcherNavBar({routes}) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route path={route.pathname}
                       exact
                       component={route.main}
                       key={index}/>
            ))}
        </Switch>
    )
}

export default TermsMain;
