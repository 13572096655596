import React, {useEffect, useRef, useState} from 'react'
import locked from '../assets/locked.svg'
import paypal from '../assets/paypal.svg'
import paypass from '../assets/paypass.svg'
import googlePay from '../assets/googlePay.svg'
import visa from '../assets/visa.svg'
import maestro from '../assets/maestro.svg'
import master_card from '../assets/master_card.svg'
import return_svg from '../assets/return.svg'
import {Link} from 'react-router-dom';
import helper from "../helper";
import {changeAuthActions, openSignModal, signRelocate} from "../main-store/header/actions";
import {deleteCart} from "../main-store/cart_and_favorite/actions";
import {connect} from "react-redux";
import DeliveryAddressModal from '../Components/DeliveryAddressModal'
import useOutsideClick from "../Constants/useOutsideClick";
import {bodyOverflow} from "../Constants/bodyOverflow";
import API from "../API";

function OrderPreview({goods, openSignModal, changeAuthActions, signRelocate, deleteCart}) {
    let [totalPrice, setTotalPrice] = useState(0);
    let [modal, setModal] = useState(false);
    let [promoValue, setPromoValue] = useState('');
    let [successfulOrder, setSuccessfulOrder] = useState(false)
    let [sale, setSale] = useState(null)
    let [promoError, setPromoError] = useState(null)
    let deliveryPrice = 0;
    let ref = useRef();

    useOutsideClick(ref, () => {
        if (modal) {
            bodyOverflow(false)
            setModal(false)
        }
    })
    useEffect(() => {
        let allPrice = 0;
        goods.forEach(item => {
            allPrice += item.price * item.quantity
        })
        setTotalPrice(allPrice)
    }, [goods]);

    let orderTotal = (title, className, price, sale = null) => {
        let priceStr = price.toString();
        return (
            <div className={'wrapper_every_order_total'}>
                <span className={className}>{title}</span>
                <div className={'wrapper_price'}>
                    {sale && price ?
                        <span className={'sale_price'}>
                            {price ? '€' : null}{price}
                        </span>
                        : sale && !price ?
                            <span className={'procent'}>
                                {'- %' + sale}
                            </span>
                            : null}
                    {priceStr && !sale ?
                        <span className={className}
                              style={{textAlign: "right"}}>
                                {price ? '€' : null}{priceStr}
                        </span>
                        : sale && priceStr ?
                            <span className={className}
                                  style={{textAlign: "right"}}>
                                {price ? '€' : null}{(priceStr - (priceStr / 100 * sale)).toFixed()}
                        </span> : null}
                </div>
            </div>
        )
    }

    let handleSubmit = event => {
        event.preventDefault();
        bodyOverflow(true)
        if (localStorage.getItem(helper.JWT_TOKEN)) {
            setModal(true)
        } else {
            signRelocate(true)
            changeAuthActions('sign_in')
            openSignModal(true)
        }
    }

    let handleAddPromo = () => {
        API.get(`/get_promo_code?promo_code=${promoValue}`)
            .then(res => {
                setSale(res.data.sale)
                setPromoError(null)
            })
            .catch(err => {
                setSale(null)
                setPromoError(err.response.data.error)
            })
    }

    return (
        <>
            <div className={'wrapper_order_preview'}>
                <div className={'wrapper_delivery_description'}>
                    <div className={'wrapper_security_pay'}>
                        <img src={locked} alt="locked"/>
                        <span className={'open_sans_400'}>
                        Защищённый платёж
                    </span>
                    </div>
                    <div className={'wrapper_security_pay_icons'}>
                        <img src={master_card} alt="master_card"/>
                        <img src={googlePay} alt="googlePay"/>
                        <img src={paypass} alt="paypass"/>
                        <img src={visa} alt="visa"/>
                        <img src={paypal} alt="paypal"/>
                        <img src={maestro} alt="maestro"/>
                    </div>
                    <div className={'wrapper_return'}>
                        <div className={'wrapper_security_pay'}>
                            <img src={return_svg} alt="return_svg"/>
                            <span className={'open_sans_400'}>Возврат</span>
                        </div>
                        <div className={'wrapper_return_desc'}>
                        <span className={'open_sans_300'}>
                            В случае продажи товара ненадлежащего качества, при необходимости, проводится экспертиза.
                        </span>
                        </div>
                        <div className={'wrapper_delivery_link'}>
                            <Link to={'/terms/delivery'} className={'open_sans_400'}>
                                Узнать больше о доставке и возврате денег
                            </Link>
                        </div>
                    </div>
                </div>
                <form className={'wrapper_order_sum wrapper_submit_request'} onSubmit={handleSubmit}>
                    <div className={'title_wrapper'}>
                    <span className={'playfair_display_400'}>
                        Сумма заказа
                    </span>
                    </div>
                    {orderTotal('Промежуточная сумма', 'open_sans_300', totalPrice)}
                    {orderTotal('Доставка', 'open_sans_300', deliveryPrice)}
                    {orderTotal('Всего', 'open_sans_600', totalPrice + deliveryPrice, sale)}
                    <div className={'wrapper_promo_code'}>
                        <input className={'input_promo_code'}
                               value={promoValue}
                               onChange={e => {
                                   setPromoError(null);
                                   setPromoValue(e.target.value)
                               }}
                               placeholder={'Введите промокод'}/>
                        <div className={'wrapper_submit_promo open_sans_600'}
                             onClick={handleAddPromo}>
                            Добавить
                        </div>
                        {promoError ?
                            <span className={'wrapper_promo_error'}>
                                {promoError}
                            </span>
                            : null}
                        <button className={'submit'}>Оплата</button>
                    </div>
                </form>
            </div>
            {modal ?
                <div className={'order_preview_modal_wrapper'}>
                    <div ref={ref} className={'order_preview_modal'}>
                        <DeliveryAddressModal cartOrder
                                              sale={sale}
                                              cart={goods}
                                              deleteCart={deleteCart}
                                              successfulOrder={successfulOrder}
                                              setSuccessfulOrder={val => setSuccessfulOrder(val)}/>
                    </div>
                </div>
                : null}
        </>
    )
}

const mapStateToProps = () => {
    return {}
};

const putStateToProps = {
    openSignModal,
    deleteCart,
    changeAuthActions,
    signRelocate
}

export default connect(mapStateToProps, putStateToProps)(OrderPreview);