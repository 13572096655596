import React, {useEffect, useState} from 'react'
import PageTitle from "../Components/PageTitle"
import SortBy from "../Components/SortBy"
import "./styles/catalog_page.css"
import {ScrollToTop} from "../Constants/ScrollToTop"
import CatalogFilters from '../Components/CatalogFilters'
import EveryGoods from "../Components/EveryGoods"
import CatalogFindItems from "../Components/CatalogFindItems"
import vector from "../assets/vector.svg"
import {useLocation} from 'react-router-dom';
import API from "../API";
import Pagination from "react-js-pagination";
import {BrandsSlider} from "../Components/BrandsSlider";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
const CataloguePage = React.memo(() => {
    const [activeSortBy, changeActiveSortBy] = useState({
        variant: 'Popular',
        request_variant: null
    });
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);
    const [goods, setGoods] = useState(() => null);
    const [filters_data, getFilters] = useState(() => null);
    const [categoryId, setCategoryId] = useState(() => null);
    const [subcategoryId, setSubcategoryId] = useState(() => null);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(() => 1);
    const [categoryPreviewData, setCategoryPreviewData] = useState(null);
    const [filtersMobile, changeFiltersMobile] = useState(null);
    const [url, setUrl] = useState(null);
    const location = useLocation();

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    const settings = {
        speed: 400,
        slidesToShow: 6,
        infinite: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 450,
                centerMode: true,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };


    useEffect(() => {
        setUrl(window.location.href)
        ScrollToTop()
    }, []);

    let changeActivePagePagination = pageNumber => {
        setActivePage(pageNumber);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        let getLocationCategoryHref;
        getLocationCategoryHref = location.pathname.split('/')[1].split('_')[1];
        let getLocationPreCategoryHref = location.pathname.split('/')[2]?.split('_')[1];
        // warning in console
        API.get(`/category_description?category_id=${getLocationCategoryHref}`)
            .then(res => {
                setCategoryPreviewData(res.data)
            })
        setCategoryId(getLocationCategoryHref);
        API.get(`/get_filters?category_id=${getLocationCategoryHref}`)
            .then(res => {
                getFilters(res.data)
                setSubcategoryId(getLocationPreCategoryHref);
                changeActiveSortBy({
                    variant: 'Popular',
                    request_variant: null
                });
                setLoading(true)
            });
    }, [location.pathname]);

    return (
        <div className={'wrapper_main_page'}>
            {categoryPreviewData ?
                <>
                    <Helmet>
                        <meta property="og:title" content={`Vinteriors store - ${categoryPreviewData.title}`}/>
                        <title>Vinteriors store - {categoryPreviewData.title}</title>
                        <meta property="og:url" content={url}/>
                        <meta property="og:image" content={logo}/>
                        <meta property="description" content={categoryPreviewData.description}/>
                        <meta property="og:description" content={categoryPreviewData.description}/>
                        <meta property="twitter:title" content={`Vinteriors store - ${categoryPreviewData.title}`}/>
                        <meta property="twitter:description" content={categoryPreviewData.description}/>
                        <meta property="twitter:image" content={logo}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                    </Helmet>
                <div className={'preview_catalog_category'}>
                    <img src={process.env.REACT_APP_API_URL + '/' + categoryPreviewData.image} alt="backgroundCatalog"/>
                    <div className={'wrapper_info_catalog_preview'}>
                        <span className={'playfair_display_400 title_text'}>
                            {categoryPreviewData.title_ru}
                        </span>
                        <span className={'open_sans_300 desc_text'}
                              dangerouslySetInnerHTML={{
                                  __html: categoryPreviewData.description_ru
                              }}/>
                    </div>
                </div>
                </>
                : null}
            <div className={'wrapper_catalog_main_content'}>
                <div className={'wrapper_filters delete_mobili'}>
                    <PageTitle title={'Каталог'}/>
                    {filters_data && loading && (
                        <CatalogFilters categoryId={categoryId}
                                        setSubcategoryId={setSubcategoryId}
                                        setGoods={e => setGoods(e)}
                                        getFilters={getFilters}
                                        activePage={activePage}
                                        setActivePage={e => setActivePage(e)}
                                        subcategoryId={subcategoryId}
                                        activeSortBy={activeSortBy}
                                        filters_data={filters_data}/>
                    )}
                </div>
                <div className={'wrapper_content'}>
                    <div className={'wrapper_filters mobili_version'}>
                        <div className={'wrapper_show_filter'}
                             onClick={() => changeFiltersMobile(!filtersMobile)}>
                            <span className={'roboto_400'}>Фильтр</span>
                            <img src={vector}
                                 className={filtersMobile ?
                                     'active-image' :
                                     ''}
                                 alt="vector"/>
                        </div>
                    </div>
                    {filtersMobile && categoryId ? (
                        <CatalogFilters categoryId={categoryId}
                                        setGoods={e => setGoods(e)}
                                        setSubcategoryId={setSubcategoryId}
                                        getFilters={getFilters}
                                        subcategoryId={subcategoryId}
                                        className={'delete_filter_lap'}
                                        activePage={activePage}
                                        activeSortBy={activeSortBy}
                                        filters_data={filters_data}/>
                    ) : null}
                    <div className={'wrapper_header_content'}>
                        <SortBy activeSortBy={activeSortBy}
                                changeActiveSortBy={changeActiveSortBy}/>
                        <div className={'wrapper_goods_length'}>
                            <span className={'playfair_display_400'}>
                                {goods?.total} товар
                            </span>
                        </div>
                    </div>
                    <BrandsSlider settings={settings}/>
                    <div className={'wrapper_catalogue_items'}>
                        {loading && goods?.total ?
                            goods?.products.map(item => {
                                return (
                                    <EveryGoods key={item.product_id}
                                                changeContinuePopup={changeContinuePopup}
                                                item={item}/>
                                )
                            }) : loading && goods ?
                                <CatalogFindItems/>
                                : null
                        }
                    </div>
                    {goods?.total > 9 ?
                        <div className={'wrapper_pagination'}>
                            <Pagination
                                activePage={Number(activePage)}
                                itemsCountPerPage={27}
                                hideFirstLastPages={goods.pages < 3}
                                firstPageText={activePage >= 3 && goods.pages > 3 ? '1' : null}
                                prevPageText={<img src={vector} className={'prev_page_pagination'} alt="vector"/>}
                                nextPageText={<img src={vector} className={'next_page_pagination'} alt="vector"/>}
                                totalItemsCount={goods.total}
                                lastPageText={activePage <= goods.pages - 2 && goods.pages > 3 ?
                                        goods.pages.toString() :
                                        null}
                                pageRangeDisplayed={3}
                                onChange={pageNumber => changeActivePagePagination(pageNumber)}/>
                        </div>
                        : null}
                </div>
            </div>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
        </div>
    )
});

export default CataloguePage
