import React, {useEffect, useState} from 'react'
import LeaveARequest from '../Components/LeaveARequest'
import PreviewCategorySectionSlider from '../Components/PreviewCategorySectionSlider'
import PreviewSectionCategory from '../Components/PreviewSectionCategory'
import {ScrollToTop} from "../Constants/ScrollToTop"
import preview_image_DELETE from '../assets/preview_image_DELETE.png'
import preview_image_DELETE2 from '../assets/preview_image_DELETE2.png'
import preview_image_DELETE3 from '../assets/preview_image_DELETE3.png'
import preview_image_DELETE4 from '../assets/preview_image_DELETE4.png'
import preview_image_DELETE5 from '../assets/preview_image_DELETE5.png'
import all_categories1 from '../assets/delux01.png'
import all_categories2 from '../assets/all_categories2.png'
import all_categories3 from '../assets/delux02.png'
import all_categories4 from '../assets/delux03.png'
import all_categories5 from '../assets/all_categories5.png'
import how_we_work1 from '../assets/how_we_work1.svg'
import how_we_work2 from '../assets/how_we_work2.svg'
import how_we_work3 from '../assets/how_we_work3.svg'
import logo from '../assets/Interiors.png'
import './styles/landing.css';
import API from "../API";
import {BrandsSlider} from "../Components/BrandsSlider";
import {Helmet} from "react-helmet";

let how_we_work_object = [
    {
        title: 'Дизайн интерьера',
        image: how_we_work1,
        description: 'Мы команда опытных дизайнеров дизайнеров.'
    },
    {
        title: 'Доставка и оплата',
        image: how_we_work2,
        description: 'Мы подбираем для Вас мебель, технику и другие предметы интерьера.'
    },
    {
        title: 'Установка и отладка',
        image: how_we_work3,
        description: 'Наши специалисты собирают всю мебель и расставляют предметы интерьера по согласованному дизайн проекту'
    },
];

const settings = {
    speed: 400,
    slidesToShow: 8,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 8,
            }
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 3,
            }
        },{
            breakpoint: 450,
            centerMode: true,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};

const Landing = React.memo(() => {
    const [newItems, setNewItems] = useState(null);
    const [popularItems, setPopularItems] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        ScrollToTop();
        setUrl(window.location.href)

        API.get('/new_products?limit=15')
            .then(res => {
                setNewItems(res.data.products)
            });

        API.get('/popular_products?limit=15')
            .then(res => {
                setPopularItems(res.data.products)
            })
    }, []);

    let howWeWork = () => {
        return (
            <div className={'wrapper_how_we_work'}>
                {how_we_work_object.map(item => {
                    return (
                        <div className={'every_block'} key={item.title}>
                            <div className={'wrapper_image'}>
                                <img src={item.image} alt={item.title}/>
                            </div>
                            <div className={'wrapper_title'}>
                                <span className={'playfair_display_400'}>{item.title}</span>
                            </div>
                            <div className={'wrapper_desc'}>
                                <span className={'open_sans_300'}>{item.description}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    let allCategory = () => {
        return (
            <div className={'wrapper_all_categories_landing'}>
                <div className={'title_preview_category_wrapper'}>
                    <span className={'playfair_display_400'}>
                        Категории
                    </span>
                    {/*<div className={'link_to_all_categories wrapper_submit_request'}>*/}
                    {/*    <Link to={'/brands'} className={'link'}>*/}
                    {/*        Больше*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
                <div className={'wrapper_all_categories_block'}>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Мебель'}
                                            image={all_categories1}
                                            categoryDesc={'Больше 10,000 категорий'}
                                            link={'category_1'}/>
                    <PreviewSectionCategory wrapper_class_theme={'light'}
                                            categoryName={'Кухня'}
                                            image={all_categories2}
                                            link={'/category_8'}
                                            categoryDesc={'Больше 10,000 категорий'}/>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Уличная мебель'}
                                            image={all_categories3}
                                            link={"category_2"}
                                            categoryDesc={'Больше 10,000 категорий'}/>
                    <PreviewSectionCategory wrapper_class_theme={'light'}
                                            categoryName={'Освещение'}
                                            image={all_categories5}
                                            link={"category_9"}
                                            categoryDesc={'Больше 10,000 Категорий'}/>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Аксессуары'}
                                            image={all_categories4}
                                            link={"category_5"}
                                            categoryDesc={'Больше 5,000 категорий'}/>

                </div>
            </div>
        )
    };

    return (
        <div className={'wrapper_main_page'}>
            <Helmet>
                <meta property="og:title" content={'Vinteriors - Ваш комфорт имеет значение.'}/>
                <title>Vinteriors - Ваш комфорт имеет значение.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Vinteriors - Ваш комфорт имеет значение.'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <PreviewSectionCategory wrapper_class_theme={'dark'}
                                    className={'wrapper_landing_banner'}
                                    categoryName={'Создайте интерьер мечты'}
                                    image={preview_image_DELETE3}
                                    link={"/category_1"}
                                    categoryDesc={'Vinteriors представляет лучшие авторские дизайн проекты, премиум мебель и домашние аксессуары.'}/>
            <div className={'wrapper_banners'}>
                <PreviewSectionCategory wrapper_class_theme={'dark'}
                                        categoryName={'Комфортные кресла'}
                                        image={preview_image_DELETE4}
                                        link={"/category_1"}
                                        categoryDesc={'Бестселлеры и эксклюзивная мебель для самых требовательных'}/>
                <PreviewSectionCategory wrapper_class_theme={'light'}
                                        categoryName={'Роскошные столы'}
                                        image={preview_image_DELETE5}
                                        link={"/category_8"}
                                        categoryDesc={'В нашем ассортименте более 300 брендов мебели.'}/>
            </div>
            <BrandsSlider settings={settings}/>
            {howWeWork()}
            <PreviewSectionCategory wrapper_class_theme={'dark'}
                                    categoryName={'Vinteriors'}
                                    image={preview_image_DELETE2}
                                    link={"/category_1"}
                                    categoryDesc={'Ваш дом.'}/>
            {newItems ? <PreviewCategorySectionSlider title={'Новинки'}
                                                      goods={newItems}
                                                      catalogLink={'/catalogue/new_products'}/>
                : null}
            {allCategory()}
            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    categoryName={'Мебель'}
                                    image={preview_image_DELETE}
                                    link={"/category_1"}
                                    categoryDesc={'Известные бренды'}/>
            {popularItems ? <PreviewCategorySectionSlider title={'Бестселлеры'}
                                                      goods={popularItems}
                                                      catalogLink={'/catalogue/popular_products'}/> : null}
            <LeaveARequest/>
        </div>
    )
});

export default Landing
