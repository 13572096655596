import axios from 'axios'
import helper from "./helper";

function API(token = null) {
    let baseURL = process.env.REACT_APP_API_URL + "/api";
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (localStorage.getItem(helper.JWT_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(helper.JWT_TOKEN);
    }

    let api = axios.create({
        baseURL: baseURL,
        timeout: 60000,
        headers: headers
    })

    api.interceptors.request.use(
        request => requestHandler(request));

    api.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    );

    const successHandler = response => response
    const requestHandler = request => request

    const errorHandler = error => {
        console.log(error)

        if (error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            // console.log(error)
            //userLogout();
            return Promise.reject({...error})
        } else if (error.response.status === 404) {
            window.location = "/404"
            return Promise.reject({...error})
        } else {
            return Promise.reject({...error})
        }
    };
    return api
}

export function userLogout() {
    API().post("/user/logout")
        .then(() => forceLogout())
        .catch(() => forceLogout())
}

export function forceLogout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location = '/';
}

export default API