import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function TermsAndConditions(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Условия — Vinteriors store.'}/>
                <title>Условия — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Условия — Vinteriors store.'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className={'playfair_display_400'}>
                <h2 className={'title'}>Условия использования</h2>
                <h3 className={'subtitle'}>Обмен и возврат товаров хорошего качества</h3>
                <p className={'content'}>{'В случае продажи товара ненадлежащего качества, при необходимости, проводится экспертиза.'}</p>
                <p className={'content'}>{'Если в результате экспертизы будет установлено, что дефекты товара возникли в связи с обстоятельствами, за которые Продавец не несет ответственности, Покупатель обязан возместить расходы на экспертизу, а также расходы, связанные с ее проведением при хранении и транспортировке товара...'}</p>
                <p className={'content'}>{'В случае, если экспертиза установила ненадлежащее качество товара, Продавец обменивает товар или возвращает деньги. Возврат средств производится не позднее 10 дней со дня подачи потребителем соответствующего запроса.'}</p>
            </div>
        </div>
    )
}
