import React, {useEffect, useState} from 'react';
import API from "../../API";
import './style/user_profile.css'
import TextInput from "../.././Components/TextInput";
import MainButton from "../.././Components/MainButton";
import CustomDateSelect from "../../Components/UserProfileComponents/CustomDateSelect";
import ChangedConfirmedPopup from '../../Components/ChangedConfirmedPopup'
import {putName} from "../../main-store/header/actions";
import {connect} from "react-redux";
import helper from "../../helper";

function UserData(props) {
    const currentYear = new Date().getUTCFullYear();
    const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const initialState = {
        surname: {
            value: '',
            error: false,
            errorMessage: '',
        },
        name: {
            value: '',
            error: false,
            errorMessage: '',
        },
        phone: {
            value: '',
            error: false,
            errorMessage: '',
        },
        gender: {
            value: 'male',
        },
        birthday: {
            fullValue: '',
            date: {
                value: '',
                placeholder: 'День',
                options: Array.from({length: 31}, (_, i) => (i + 1).toString()),
            },
            month: {
                value: '',
                placeholder: 'Месяц',
                options: Array.from({length: 12}, (_, i) => (i + 1).toString()),
            },
            year: {
                value: '',
                placeholder: 'Год',
                options: Array(currentYear - (currentYear - 70)).fill('').map((v, idx) => (currentYear - idx).toString()),
                errorPopup: false,
                errorMessage: 'Выберите день рождения из списка',
            },
        }
    }

    const passwordState = {
        oldPassword: {
            value: '',
            error: false,
            errorMessage: '',
        },
        newPassword: {
            value: '',
            error: false,
            errorMessage: '',
        },
        confirmPassword: {
            value: '',
            error: false,
            errorMessage: '',
        },
    }

    const [data, setData] = useState(() => initialState)
    const [passwordData, setPasswordData] = useState(() => passwordState)
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);
    const [emailData, setEmailData] = useState({
        email: {
            value: '',
            error: false,
            errorMessage: '',
        },
    })
    const [loading, setLoading] = useState(false)

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    useEffect(() => {
        API.get(`/user/profile`)
            .then(res => {
                if (res.data) {
                    setEmailData(prevState => ({
                        ...prevState,
                        email: {
                            ...prevState.email,
                            value: res.data.email ?? '',
                        }
                    }))
                    setData(prevState => ({
                        ...prevState,
                        name: {
                            ...prevState.name,
                            value: res.data.name ?? '',
                        },
                        surname: {
                            ...prevState.surname,
                            value: res.data.surname ?? '',
                        },
                        phone: {
                            ...prevState.phone,
                            value: res.data.phone ?? '',
                        },
                        gender: {
                            ...prevState.gender,
                            value: res.data.gender ?? 'male',
                        },
                        birthday: {
                            ...prevState.birthday,
                            fullValue: res.data.birth_date ?? '',
                            date: {
                                ...prevState.birthday.date,
                                value: res.data.birth_date ? res.data.birth_date.split('/')[0] : '',
                            },
                            month: {
                                ...prevState.birthday.month,
                                value: res.data.birth_date ? res.data.birth_date.split('/')[1] : '',
                            },
                            year: {
                                ...prevState.birthday.year,
                                value: res.data.birth_date ? res.data.birth_date.split('/')[2] : '',
                            },
                        }
                    }))
                    setLoading(true)
                }
            })
            .catch(err => console.log(err))
    }, [])

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        if (regExpEmail.test(emailData.email.value)) {
            API.post(`/user/change_email`, {
                email: emailData.email.value
            })
                .then(res => {
                    changeContinuePopup('Почта изменена успешно')
                    setTimeout(() => changeContinuePopup('Почта изменена успешно'), 2000);
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.error === "email is already busy") {
                        setEmailData(prevState => ({
                            email: {
                                ...prevState.email,
                                error: true,
                                errorMessage: 'Почта уже занята'
                            }
                        }))
                    }
                })
        } else {
            setEmailData(prevState => ({
                email: {
                    ...prevState.email,
                    error: true,
                    errorMessage: 'Неправильный формат почты'
                }
            }))
        }
    }

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        if (passwordData.oldPassword.value && passwordData.newPassword.value && passwordData.confirmPassword.value) {
            if (/\d/.test(passwordData.newPassword.value) && /\d/.test(passwordData.confirmPassword.value) && passwordData.newPassword.value.length > 5 && passwordData.confirmPassword.value.length > 5 && passwordData.newPassword.value === passwordData.confirmPassword.value) {
                API.post(`/user/change_password`, {
                    old_password: passwordData.oldPassword.value,
                    new_password: passwordData.newPassword.value
                })
                    .then(res => {
                        if (res.status === 200) {
                            changeContinuePopup('Пароль изменён успешно')
                            setTimeout(() => changeContinuePopup('Пароль изменён успешно'), 2000);
                            setPasswordData(prevState => ({
                                oldPassword: {
                                    ...prevState.oldPassword,
                                    value: '',
                                },
                                newPassword: {
                                    ...prevState.newPassword,
                                    value: '',
                                },
                                confirmPassword: {
                                    ...prevState.confirmPassword,
                                    value: '',
                                }
                            }))
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.data.error === 'wrong password') {
                            setPasswordData(prevState => ({
                                ...prevState,
                                oldPassword: {
                                    ...prevState.oldPassword,
                                    error: true,
                                    errorMessage: 'Неправильный старый пароль',
                                }
                            }))
                        }
                    })
            } else if(passwordData.newPassword.value !== passwordData.confirmPassword.value) {
                setPasswordData(prevState => ({
                    ...prevState,
                    newPassword: {
                        ...prevState.newPassword,
                        error: true,
                        errorMessage: 'Пароли должны совпадать'
                    },
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        error: true,
                        errorMessage: 'Пароли должны совпадать'
                    }
                }))
            } else if (!/\d/.test(passwordData.newPassword.value) || !/\d/.test(passwordData.confirmPassword.value) || !passwordData.newPassword.value.length <= 5 || passwordData.confirmPassword.value.length <= 5) {
                setPasswordData(prevState => ({
                    ...prevState,
                    newPassword: {
                        ...prevState.newPassword,
                        error: true,
                        errorMessage: 'Пароль должен состоять из 6 символов, включая минимум 1 цифру'
                    },
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        error: true,
                        errorMessage: 'Пароль должен состоять из 6 символов, включая минимум 1 цифру'
                    }
                }))
            }
        } else {
            Object.entries(passwordData).forEach(item => {
                if (!item[1].value) {
                    setPasswordData(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Это поле должно быть заполнено'}
                    }))
                }
            })
        }
    }

    const handleUserData = (e) => {
        e.preventDefault();
        const requestValues = data.birthday.date.value && data.birthday.month.value && data.birthday.year.value && data.name.value && data.surname.value && data.phone.value
        if (requestValues) {
            API.patch(`/user/profile`, {
                name: data.name.value,
                surname: data.surname.value,
                gender: data.gender.value,
                phone: data.phone.value,
                birth_date: data.birthday.fullValue
            })
                .then(res => {
                    if(res.status === 200) {
                        props.putName(data.name.value)
                        let localData = JSON.parse(localStorage.getItem(helper.USER_DATA));
                        localData.name = data.name.value
                        localStorage.setItem(helper.USER_DATA, JSON.stringify(localData));
                    }
                    changeContinuePopup()
                    setTimeout(() => changeContinuePopup(), 500);
                })
        } else {
            Object.entries(data).forEach(item => {
                if (!item[1].value) {
                    setData(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Fill this label'}
                    }))
                }
            })
            if (!data.birthday.date.value || !data.birthday.month.value || !data.birthday.year.value) {
                setData(prevState => ({
                    ...prevState,
                    birthday: {
                        ...prevState.birthday,
                        year: {
                            ...prevState.birthday.year,
                            errorPopup: true,
                        },
                    }
                }))
            }
        }
    }

    useEffect(() => {
        const requestValues = data.birthday.date.value && data.birthday.month.value && data.birthday.year.value
        if (requestValues) {
            setData(prevState => ({
                ...prevState,
                birthday: {
                    ...prevState.birthday,
                    fullValue: `${data.birthday.date.value}/${data.birthday.month.value}/${data.birthday.year.value}`
                }
            }))
        }
    }, [data.birthday.date.value, data.birthday.month.value, data.birthday.year.value])

    return loading && (
        <div className={'wrapper_user_profile_data'}>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <h3>Мои данные</h3>
            <div>
                <span>Почта</span>
                <form className={'wrapper_user_profile_data-email'} onSubmit={(e) => handleSubmitEmail(e)}>
                    <TextInput error={emailData.email.error}
                               errTitle={emailData.email.errorMessage}
                               type={'text'}
                               value={emailData.email.value}
                               onChange={({target}) => {
                                   setEmailData(prevState => ({
                                       ...prevState,
                                       email: {
                                           ...prevState.email,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}
                    />
                    <MainButton type={'submit'}
                                wrapperStyle={{display: 'flex'}}
                                style={{padding: '16px 1.5rem', margin: '0'}}
                                button={'main'}
                                text={'Сохранить'}/>
                </form>
            </div>
            <div className={'wrapper_user_profile_data-line'}/>
            <form className={'wrapper_user_profile_data_user_data'} onSubmit={(e) => handleUserData(e)}>
                <div className={'wrapper_user_profile_data_user_name'}>
                    <div>
                        <span>Фамилия</span>
                        <TextInput error={data.surname.error}
                                   errTitle={data.surname.errorMessage}
                                   type={'text'}
                                   value={data.surname.value}
                                   onChange={({target}) => {
                                       setData(prevState => ({
                                           ...prevState,
                                           surname: {
                                               ...prevState.surname,
                                               value: target.value,
                                               error: false,
                                           }
                                       }))
                                   }}
                        />
                    </div>
                    <div>
                        <span>Имя</span>
                        <TextInput error={data.name.error}
                                   errTitle={data.name.errorMessage}
                                   type={'text'}
                                   value={data.name.value}
                                   onChange={({target}) => {
                                       setData(prevState => ({
                                           ...prevState,
                                           name: {
                                               ...prevState.name,
                                               value: target.value,
                                               error: false,
                                           }
                                       }))
                                   }}
                        />
                    </div>
                </div>
                <div className={'wrapper_user_profile_data_user_phone'}>
                    <div>
                        <span>Номер телефона</span>
                        <TextInput error={data.phone.error}
                                   errTitle={data.phone.errorMessage}
                                   type={'text'}
                                   value={data.phone.value}
                                   onChange={({target}) => {
                                       const RegEx = /^\+?(?:[\d]*)$/;
                                       if (RegEx.test(target.value) && target.value.length < 14) {
                                           setData(prevState => ({
                                               ...prevState,
                                               phone: {
                                                   ...prevState.phone,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }
                                   }}
                        />
                    </div>
                    <div>
                        <span>Пол</span>
                        <div onClick={() => setData(prevState => ({
                            ...prevState,
                            gender: {
                                value: 'female'
                            }
                        }))} className={'wrapper_user_profile_data_user_options'}>
                            <div
                                className={`wrapper_user_profile_data_user_options_button ${data.gender.value === 'female' ? 'wrapper_user_profile_data_user_options_button_active' : ''}`}>
                                <div/>
                            </div>
                            <p>Женский</p>
                        </div>
                        <div onClick={() => setData(prevState => ({
                            ...prevState,
                            gender: {
                                value: 'male'
                            }
                        }))} className={'wrapper_user_profile_data_user_options'}>
                            <div
                                className={`wrapper_user_profile_data_user_options_button ${data.gender.value === 'male' ? 'wrapper_user_profile_data_user_options_button_active' : ''}`}>
                                <div/>
                            </div>
                            <p>Мужской</p>
                        </div>
                    </div>
                </div>
                <div className={'wrapper_user_profile_data_birth_date'}>
                    <CustomDateSelect setData={val => setData(val)} itemsList={data.birthday.date.options}
                                      val={data.birthday.date.value} setVal={'date'}
                                      placeholder={data.birthday.date.placeholder}/>

                    <CustomDateSelect setData={val => setData(val)} itemsList={data.birthday.month.options}
                                      val={data.birthday.month.value} setVal={'month'}
                                      placeholder={data.birthday.month.placeholder}/>

                    <CustomDateSelect setData={val => setData(val)} itemsList={data.birthday.year.options}
                                      val={data.birthday.year.value} placeholder={data.birthday.year.placeholder}
                                      setVal={'year'} popup={data.birthday.year.errorPopup}
                                      errorMessage={data.birthday.year.errorMessage}/>
                </div>

                <MainButton type={'submit'}
                            wrapperStyle={{display: 'flex'}}
                            style={{padding: '16px 1.5rem', margin: '0'}}
                            button={'main'}
                            text={'Сохранить'}/>
            </form>
            <div className={'wrapper_user_profile_data-line'}/>
            <div>
                <form className={'wrapper_user_profile_data-email wrapper_user_profile_data-password'}
                      onSubmit={(e) => handleSubmitPassword(e)}>
                    <span>Старый пароль</span>
                    <TextInput error={passwordData.oldPassword.error}
                               errTitle={passwordData.oldPassword.errorMessage}
                               type={'password'}
                               showPass
                               margin={'0 0 1.5rem'}
                               value={passwordData.oldPassword.value}
                               onChange={({target}) => {
                                   setPasswordData(prevState => ({
                                       ...prevState,
                                       oldPassword: {
                                           ...prevState.oldPassword,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}
                    />
                    <span>Новый пароль</span>
                    <TextInput error={passwordData.newPassword.error}
                               errTitle={passwordData.newPassword.errorMessage}
                               type={'password'}
                               showPass
                               margin={'0 0 1.5rem'}
                               value={passwordData.newPassword.value}
                               onChange={({target}) => {
                                   setPasswordData(prevState => ({
                                       ...prevState,
                                       newPassword: {
                                           ...prevState.newPassword,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}
                    />
                    <span>Подтвердите пароль</span>
                    <TextInput error={passwordData.confirmPassword.error}
                               errTitle={passwordData.confirmPassword.errorMessage}
                               type={'password'}
                               showPass
                               margin={'0 0 1.5rem'}
                               value={passwordData.confirmPassword.value}
                               onChange={({target}) => {
                                   setPasswordData(prevState => ({
                                       ...prevState,
                                       confirmPassword: {
                                           ...prevState.confirmPassword,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}
                    />
                    <MainButton type={'submit'}
                                wrapperStyle={{display: 'flex'}}
                                style={{padding: '16px 1.5rem', margin: '0'}}
                                button={'main'}
                                text={'Сохранить'}/>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user_name: state.header.user_name
    }
};

const putStateToProps = {
    putName
}

export default connect(mapStateToProps, putStateToProps)(UserData);
