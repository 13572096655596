import React from "react";
import TextInput from "./TextInput";
import MainButton from "./MainButton";
import DeliveryAddressHOC from '../HOC/DeliveryAddressHOC'
import successfulIcon from '../assets/cart-modal-confirm-icon.svg'
import {Link} from 'react-router-dom'
import ChangedConfirmedPopup from "./ChangedConfirmedPopup";
import {bodyOverflow} from "../Constants/bodyOverflow";
import './styles/delivery_address_modal.css'

function DeliveryAddressModal({data, successText, changingConfirmed, handleInputChange, changeDeliveryAddress, cartOrder, successfulOrder}) {
    return (
        <>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            {successfulOrder ?
                <div className={'delivery_address_modal_confirmed'}>
                    <img src={successfulIcon} alt="successful order"/>
                    <h4>Заказ добавлен успешно</h4>
                    <p>Мы с вами вскоре свяжемся.</p>
                    <Link to={'/'}
                          onClick={() => bodyOverflow(false)}>
                        Вернуться на главную
                    </Link>
                </div>
                : <div className={'wrapper_user_profile_address'}>
                    {cartOrder ?
                        <h3>Проверить адрес доставки</h3>
                        : null}
                    <form className={'wrapper_user_profile_address-data'}
                          onSubmit={(e) => {
                              changeDeliveryAddress(e, cartOrder)
                          }}>
                        <div>
                            <span>Страна</span>
                            <TextInput error={data.country.error}
                                       errTitle={data.country.errorMessage}
                                       type={'text'}
                                       name={'country'}
                                       value={data.country.value}
                                       onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <span>Регион</span>
                            <TextInput error={data.region.error}
                                       errTitle={data.region.errorMessage}
                                       type={'text'}
                                       name={'region'}
                                       value={data.region.value}
                                       onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <span>Город</span>
                            <TextInput error={data.city.error}
                                       errTitle={data.city.errorMessage}
                                       type={'text'}
                                       name={'city'}
                                       value={data.city.value}
                                       onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <span>Индекс</span>
                            <TextInput error={data.zip_code.error}
                                       errTitle={data.zip_code.errorMessage}
                                       type={'text'}
                                       name={'zip_code'}
                                       value={data.zip_code.value}
                                       onChange={handleInputChange}
                                       maxLength={5}
                            />
                        </div>
                        <div className={'wrapper_user_profile_address-data_street'}>
                            <span>Адрес улицы</span>
                            <TextInput error={data.street_address.error}
                                       errTitle={data.street_address.errorMessage}
                                       name={'street_address'}
                                       type={'text'}
                                       onChange={handleInputChange}
                                       value={data.street_address.value}
                            />
                        </div>
                        <MainButton type={'submit'}
                                    wrapperStyle={{display: 'flex'}}
                                    style={{padding: '16px 1.5rem', margin: '0'}}
                                    button={'main'}
                                    text={cartOrder ? 'Подтвердить заказ' : 'Изменить адрес'}/>
                    </form>
                </div>
            }
        </>
    )
}

export default DeliveryAddressHOC(DeliveryAddressModal)