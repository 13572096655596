import React, {useRef, useState} from 'react';
import useOutsideClick from '../Constants/useOutsideClick'
import './styles/auth_modal.css'
import Input from "./Input";
import {bodyOverflow} from "../Constants/bodyOverflow";
import API from "../API";
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import helper from "../helper";

const AuthModal = React.memo(props => {
    let ref = useRef();
    const [email, changeEmail] = useState({
        value: '',
        error: false
    });
    const [password, changePassword] = useState({
        value: '',
        error: false
    });
    const [name, changeName] = useState({
        value: '',
        error: false
    });
    const [surname, changeSurname] = useState({
        value: '',
        error: false
    });
    const [repeatPassword, changeRepeatPassword] = useState({
        value: '',
        error: false
    });

    const [passwordSendToMail, changePasswordSendToMail] = useState(false);


    useOutsideClick(ref, () => {
        console.log(ref);
        console.log(props.auth_action);

        resetData();
    });

    const resetData = () => {
        props.openSignModal(null);
        props.changeAuthActions(null);
        bodyOverflow(false);
    };

    const handleSubmit = e => {
        e.preventDefault();
        // eslint-disable-next-line no-useless-escape
        let regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (passwordSendToMail) {
            props.changeAuthActions('sign_in');
            changePasswordSendToMail(false)
        } else if (props.auth_action === 'forgot_password' && regExp.test(email.value)) {
            changePasswordSendToMail(true);
        } else if (props.auth_action === 'forgot_password' && !regExp.test(email.value)) {
            changeEmail(state => ({...state, error: true}))
        }
        if (props.auth_action === 'sign_in') {
            if (regExp.test(email.value) && password.value) {
                API.post('/login', {email: email.value, password: password.value, way: 'email'})
                    .then(res => {
                        if (res.status === 200) {
                            let data = {name: res.data.name};
                            localStorage.setItem(helper.JWT_TOKEN, res.data.access_token);
                            localStorage.setItem(helper.USER_DATA, JSON.stringify(data))
                            props.putName(name.value);
                            resetData()
                            if (!props.sign_relocate) {
                                window.location.href = '/user_profile/user_data'
                            } else {
                                window.location.reload();
                            }
                        }
                    })
                    .catch(() => {
                        changeEmail(state => ({...state, error: 'Неправильная почта или пароль'}))
                        changePassword(state => ({...state, error: 'Неправильная почта или пароль'}))
                    })
            }
            if (!regExp.test(email.value)) {
                changeEmail(state => ({...state, error: true}));
            }
            if (password.value <= 6) {
                changePassword(state => ({...state, error: 'Пароль должен быть длиннее 5 символов'}));
            }
        }
        if (props.auth_action === 'sign_up') {
            if (password.value === repeatPassword.value && name.value && surname.value && regExp.test(email.value)) {
                API.post('/registration', {
                    email: email.value,
                    password: password.value,
                    name: name.value,
                    surname: surname.value,
                    way: 'email'
                })
                    .then(res => {
                        if (res.status === 200) {
                            let data = {name: name.value, surname: surname.value};
                            localStorage.setItem(helper.JWT_TOKEN, res.data.access_token);
                            localStorage.setItem(helper.USER_DATA, JSON.stringify(data));
                            props.putName(name.value);
                            resetData()
                            if (!props.sign_relocate) {
                                window.location.href = '/user_profile/user_data'
                            }
                        }
                    })
                    .catch(() => {
                        changeEmail(state => ({...state, error: 'Пользователь уже существует'}))
                    })
            }
            if (password.value !== repeatPassword.value) {
                changePassword(state => ({...state, error: true}));
                changeRepeatPassword({...repeatPassword, error: 'Пароли не совпадают'})
            } else if (password.value <= 6) {
                changePassword({...password, error: 'Пароль должен быть длинее 5 символов'});
            }
            if (!regExp.test(email.value)) {
                changeEmail({...email, error: true})
            }
            if (!name.value) {
                changeName({...name, error: true})
            }
            if (!surname.value) {
                changeSurname({...surname, error: true})
            }
        }
    };

    const authVariant = () => {
        let activeAuthVariant = 'active_auth_variant playfair_display_400';
        let unActiveAuthVariant = 'un_active_auth_variant playfair_display_400';
        return (
            <div className={'wrapper_auth_variant'}>
                {props.auth_action === 'forgot_password' ?
                    <span className={activeAuthVariant}>
                    Восстановление пароля
                </span> :
                    <>
                        <span className={props.auth_action === 'sign_in' ? activeAuthVariant : unActiveAuthVariant}
                              onClick={() => {
                                  props.changeAuthActions('sign_in');
                                  changePassword({value: '', error: false});
                                  changeRepeatPassword({value: '', error: false});
                                  changeName({value: '', error: false})
                              }}>
                            Логин
                        </span>
                        <span className={props.auth_action === 'sign_up' ? activeAuthVariant : unActiveAuthVariant}
                              onClick={() => props.changeAuthActions('sign_up')}>
                            Регистрация
                        </span>
                    </>
                }
            </div>
        )
    };

    const allInput = () => {
        return (
            <div className={'wrapper_every_inputs'}>
                {everyInput('text', email, changeEmail, "Enter your E-mail")}
                {props.auth_action !== 'forgot_password' ?
                    everyInput('password', password, changePassword,
                        props.auth_action === 'sign_up' ? 'Придумайте пароль' : "Введите пароль")
                    : null}
                {props.auth_action === 'sign_up' ?
                    <>
                        {everyInput('password', repeatPassword, changeRepeatPassword, 'Повторите пароль')}
                        {everyInput('text', name, changeName, 'Введите ваше имя')}
                        {everyInput('text', surname, changeSurname, 'Введите вашу фамилию')}
                    </>
                    : null}
            </div>
        )
    };

    const everyInput = (type, input_state, changeInput, placeholder) => {
        return (
            <Input type={type}
                   placeholder={placeholder}
                   style={{padding: '8px 0', fontSize: '16px'}}
                   handleChange={({target}) => everyInputOnChange(changeInput, target, input_state)}
                   className={input_state.error ? 'input_error' : null}
                   value={input_state.value}/>
        )
    };

    const everyInputOnChange = (changeInput, target, input_state) => {
        changeInput({...input_state, value: target.value});
        if (input_state.error) {
            changeInput({...input_state, error: false, value: target.value});
            if (input_state === repeatPassword) {
                changePassword({...password, error: false});
            }
        }
    };

    let loginSuccess = (res, version) => {
        if (res.accessToken) {
            axios.post(`${process.env.REACT_APP_API_URL}/api/${props.auth_action === 'sign_up' ? 'registration' : 'login'}`, {
                way: version,
                access_token: res.accessToken
            })
                .then(res => {
                    let data = {name: res.data.name};
                    localStorage.setItem(helper.JWT_TOKEN, res.data.access_token);
                    localStorage.setItem(helper.USER_DATA, JSON.stringify(data));
                    props.putName(res.data.name);
                    resetData()
                    if (!props.sign_relocate) {
                        window.location.href = '/user_profile/user_data'
                    }
                })
                .catch(e => console.log(e))
        }
    }

    return (
        <div className={'wrapper_every_modal'}
             ref={ref}>
            <form className={'modal'}
                  onSubmit={handleSubmit}>
                <img src={props.vector_close}
                     onClick={() => resetData()}
                     className={'close_modal'}
                     alt="vector_close"/>
                {passwordSendToMail ?
                    <div className={'wrapper_description'}>
                        <span className={'playfair_display_400'}>
                            Ссылка на возобновление пароля отправлена на вашу почту {email.value}
                        </span>
                    </div>
                    : <>
                        {authVariant()}
                        {props.auth_action !== 'forgot_password' ?
                            <div className={'wrapper_auth_btn'}>
                                <FacebookLogin
                                    appId="384258312957510"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={(e) => loginSuccess(e, 'facebook')}
                                    cssClass="my-facebook-button-class"
                                    icon="fa-facebook"
                                />
                                <GoogleLogin
                                    clientId="817964756576-97anp6a9htmgesfvdo6ftpkrd9bupcuh.apps.googleusercontent.com"
                                    buttonText="Continue with Google"
                                    onSuccess={(e) => loginSuccess(e, 'google')}
                                    cookiePolicy={'single_host_origin'}/>
                            </div>
                            : null}
                        {allInput()}



                    </>
                }
                {(props.auth_action === 'sign_up' || props.auth_action === 'sign_in') && (repeatPassword.error || password.error || email.error) ?
                    <div className={'wrapper_error_text'}>
                        <span>
                        {repeatPassword?.error || password?.error || email.error}
                    </span>
                    </div>
                    : null}
                <div className={'wrapper_submit'}
                     style={passwordSendToMail || props.auth_action === 'forgot_password' ?
                         {gridTemplateColumns: '1fr'} : null}>
                    <button>
                        {passwordSendToMail ? "OK" :
                            props.auth_action === 'sign_in' ? "Войти"
                                : props.auth_action === 'sign_up' ? "Регистрация"
                                : "Восстановление пароля"}
                    </button>
                    <div className={'forgot_password'}
                         style={props.auth_action === 'sign_in' && !passwordSendToMail ? null : {display: 'none'}}
                         onClick={() => {
                             props.changeAuthActions('forgot_password')
                         }}>
                            <span className={'open_sans_400'}>
                            Забыли пароль?
                        </span>
                    </div>
                </div>
            </form>
        </div>
    )
});

export default withRouter(AuthModal)
