import React, {useRef} from 'react'
import useOutsideClick from "../Constants/useOutsideClick";

const CustomPopup = React.memo(({handleClosePopup}) => {

    let ref = useRef(() => null);
    useOutsideClick(ref, () => {
        handleClosePopup()
    });
    return (
        <div className={'wrapper_every_modal wrapper_custom_popup'}
            ref={ref}>
            <div className={'modal'}>
                <div className={'wrapper_title'}>
                    <span className={'playfair_display_400'}>Спасибо!</span>
                    <br/>
                    <span className={'playfair_display_400'}>Мы свяжемся с вами в течении 24 часов.</span>
                </div>
                <div className={'wrapper_submit'}>
                    <button className={'open_sans_600'} onClick={() => handleClosePopup()}>
                        Продолжить
                    </button>
                </div>
            </div>
        </div>
    )
});

export default CustomPopup
