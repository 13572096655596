import React, {useState} from 'react';
import PageTitle from "../Components/PageTitle";
import "./styles/favorite_page.css";
import LeaveARequest from "../Components/LeaveARequest";
import {connect} from 'react-redux'
import {deleteFromFavorites} from "../main-store/cart_and_favorite/actions";
import EveryGoods from "../Components/EveryGoods"
import SortBy from "../Components/SortBy";
import {Link} from "react-router-dom";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";

function FavoriteGoods(props) {
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);
    const [activeSortBy, changeActiveSortBy] = useState({
        variant: 'Popular',
        request_variant: null
    });

    let changeContinuePopup = (text = "Изменения успешно сохранены") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    return (
        <div className={'wrapper_favorites_main_page'}>
            <PageTitle title={'Выбранные продукты'}/>
            <div className={'wrapper_filter_by_popular'}>
                <SortBy activeSortBy={activeSortBy} changeActiveSortBy={changeActiveSortBy}/>
                <div className={'clear_all'}>
                    <span className={'open_sans_300'} onClick={() => props.deleteFromFavorites()}>Clear list</span>
                </div>
            </div>
            <div className={"wrapper_content"}>
                {props.favorite?.map((item, index) => (
                    <EveryGoods key={index}
                                changeContinuePopup={changeContinuePopup}
                                item={item}/>
                ))}
            </div>

            <div className={"buttons_wrapper"}>
                <div className={'wrapper_submit_request preview_category_link'}>
                    <Link to={'/'} className={'link'}>
                        Продолжить покупки
                    </Link>
                </div>
                <div className={'wrapper_submit_request preview_category_link'}>
                    <Link to={'/cart'} className={'link black'}>
                        В корзину
                    </Link>
                </div>
            </div>
            <LeaveARequest/>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
        </div>
    )
}

const mapPropsToState = ({cart_favorite}) => {
    return {
        cart: cart_favorite.cart,
        recentlyViewed: cart_favorite.recentlyViewed,
        favorite: cart_favorite.favorite,
    }
};

const putStateToProps = {
    deleteFromFavorites,
};

export default connect(mapPropsToState, putStateToProps)(FavoriteGoods)
