import React, {useEffect, useState} from 'react';
import PageTitle from "../Components/PageTitle";
import PreviewSectionCategory from "../Components/PreviewSectionCategory";
import './styles/about_page.css';

// eslint-disable-next-line
import about_image_first from '../assets/about-image-first.png'
import about_image_second from '../assets/about-image-second.png'
import about_image_map from '../assets/about-map.png'

import about_image_consult from '../assets/about-icon-consult.svg'
import about_image_design from '../assets/about-icon-design.svg'
import about_image_furniture from '../assets/about-icon-furniture.svg'
import about_image_individual from '../assets/about-icon-individual.svg'
import about_image_light from '../assets/about-icon-light.svg'
import about_image_polite from '../assets/about-icon-polite.svg'
import about_image_measure from '../assets/about-icon-measure.svg'
import about_image_textile from '../assets/about-icon-textile.svg'
import {ScrollToTop} from "../Constants/ScrollToTop";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
//test

const aboutServices = [
    {
        image: about_image_consult,
        imageAlt: 'departure of the consultant',
        text: 'выезд консультанта с каталогами на объект во время, подходящее заказчика',
    },
    {
        image: about_image_furniture,
        imageAlt: 'selection of furniture',
        text: 'подбор мебели для существующего дизайна проекта',
    },
    {
        image: about_image_design,
        imageAlt: 'design development',
        text: 'разработка дизайна проекта',
    },
    {
        image: about_image_measure,
        imageAlt: 'departure of the measurer',
        text: 'выезд замерщика в удобное для заказчика время',
    },
    {
        image: about_image_individual,
        imageAlt: 'individual order',
        text: 'возможность индивидуального заказа',
    },
    {
        image: about_image_light,
        imageAlt: 'lighting systems',
        text: 'выбор и установка системы освещения',
    },
    {
        image: about_image_textile,
        imageAlt: 'textile decoration',
        text: 'текстильный дизайн интерьера',
    },
    {
        image: about_image_polite,
        imageAlt: 'adequate and prompt work with complaints',
        text: 'адекватная и оперативная работа с жалобами',
    },
];

export default function AboutCompany() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
       ScrollToTop();
       setUrl(window.location.href)
    },[]);

    let aboutBanner = () => {
        return (
            <div className="banner-image">
                <div className="about-banner-info">
                    <h2>Vinteriors</h2>
                    <p>интернет-магазин с лучшими авторскими дизайн-проектами, премиальной мебелью и аксессуарами для дома</p>
                </div>
            </div>
        )
    };

    let serviceRange = () => {
        return (
            <div className="about-services">
                <h3>Мы предоставляем полный спектр сопутствующих услуг,</h3>
                <p>необходимых при создании современного интерьера</p>
            <div className="services-range">
                {aboutServices.map((item, index) => {
                    return (
                        <div className="services-range-item" key={index}>
                            <img src={item.image} alt={item.imageAlt} />
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
            </div>
        )
    };

    return (
        <div className={'wrapper_main_page about_company_page'}>
            <Helmet>
                <meta property="og:title" content={'Про компанию — Vinteriors store'}/>
                <title>Про компанию — Vinteriors store</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Про компанию — Vinteriors store'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <PageTitle title={'О компании'}/>

            {aboutBanner()}

            {/*<PreviewSectionCategory wrapper_class_theme={'light'}*/}
            {/*                        className={'about-info'}*/}
            {/*                        categoryName={'Мы предлагаем самый широкий выбор мебели,'}*/}
            {/*                        categoryDesc={'а также варианты готовых интерьерных решений с подбором дизайнерской мебели, освещения и текстиля не только из Италии, но и из Германии, США, Швейцарии и Франции. В то же время, мы предлагаем нашим клиентам, дизайнерам и архитекторам только высококачественную продукцию премиум класса.'}*/}
            {/*                        image={about_image_first}*/}
            {/*                        link={'/brands'} />*/}

            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    className={'about-info about-info-reverse'}
                                    categoryName={'У нас разумные и конкурентоспособные цены'}
                                    categoryDesc={'Выбирайте из тысяч продуктов и мебельных решений. Просмотрите продукты, выбранные архитекторами и декораторами, и посмотрите, как они использовали их в проектах.'}
                                    image={about_image_second}
                                    link={'/'}
                                    hideButton />

            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    className={'about-map'}
                                    categoryName={'Мы доставляем по всему миру!'}
                                    categoryDesc={'Мы стремимся выполнить все заказы и, возможно, сможем доставить к вашему пункту назначения, даже если он не указан выше. Пожалуйста, свяжитесь с нами по адресу request@vinteriors.store для обсуждения вопросов доставки.'}
                                    image={about_image_map}
                                    link={'/terms/delivery'}
                                    linkText={'Подробнее'}/>
            {serviceRange()}
        </div>
    )

}
