import React, {useState, useRef, useEffect} from 'react';
import API from "../adminAPI";

import Layout from "../adminComponents/Layout"
import IconExit from "../assets/g-modal-close.svg"
import '../adminComponents/style/order_popup.css'
import "../adminComponents/style/orders.scss"

const OrdersPage = () => {

    const [popupPrices, setPopupPrices] = useState([])
    const [popupComment, setPopoupComment] = useState("")
    const [ordersData, setOrdersData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [modal, setModal] = useState(false);
    const [options, setOptions] = useState({
        in_archive: 0,
        per_page: 20
    });

    const [showCount, setShowCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [leftCount, setLeftCount] = useState(0);

    const [loading, setLoading] = useState(false);

    const ref = useRef();

    const handleModalOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false);
        }
    };

    useEffect(() => {
        API.get("/admin/orders", {params: {per_page: 20 + showCount, in_archive: options.in_archive}})
            .then(res => {
                console.log(res);
                setTotalCount(res.data.total_orders);
                setLeftCount(res.data.total_orders - res.data.orders.length);
                setLoading(false);

                setOrdersData(res.data.orders)
            });

        if (modal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        document.addEventListener("mousedown", handleModalOutside);
        return () => document.removeEventListener("mousedown", handleModalOutside);
    }, [modal, showCount, options.in_archive]);

    const showMore = () => {
        if (totalCount / leftCount < 1) {
            setShowCount(totalCount)
        } else if (totalCount / leftCount > 1) {
            setShowCount(prevState => prevState + 20)
        }
    };

    const handleTabNew = () => {
        setOptions({...options, in_archive: 0});

        API.get(`/admin/orders`, {params: {per_page: 20 + showCount}})
            .then(res => {
                console.log(res)
                setOrdersData([]);
                setOrdersData(res.data.orders)
            })
    };

    const handleTabOld = () => {
        setOptions({...options, in_archive: 1});

        API.get(`/admin/orders`, {params: {in_archive: 1, per_page: 20 + showCount}})
            .then(res => {
                console.log(res);
                setOrdersData([]);
                setOrdersData(res.data.orders)
            })
    };


    const handleModal = () => {
        API.post("/admin/orders",
            {
                order_id: modalData.order_id,
                price_of_products: popupPrices,
                comment: popupComment
            })
            .catch(e => console.log(e))
        setModal(!modal);
    };

    const handleModalData = id => {
        API.get("/admin/orders", {params: {order_id: id}})
            .then(res => {
                setPopupPrices([])
                setModalData(res.data)
                let prices = [];
                res.data.products.forEach(product => {
                    prices.push({
                        'price': product.default_price * product.quantity,
                        'ordered_product_id': product.ordered_product_id
                    })
                })
                setPopupPrices(prices)
            });
    };

    return (
        <Layout>
            <section className="orders">
                <div className="orders__wrapper container">

                    {modal ? <div className="g-modal">
                        <div ref={ref} style={{width: '800px', height: '90vh', overflow: 'auto'}}
                             className="g-modal__wrapper">
                            <div className="g-modal__head">
                                <h4 className="g-modal__title">Рассчёт заказа для {modalData.username}</h4>
                                <img className="g-modal__icon" src={IconExit} alt="Замовлення" onClick={handleModal}/>
                            </div>

                            <div className={'calculate_order_wrapper'}>
                                {popupPrices.length > 0 && modalData.products && modalData.products.map((product, index) => {
                                    let sum = product.default_price * product.quantity;

                                    return (
                                        <div className={'product_wrapper'}>
                                            <div className={'product_top'}>
                                                <img
                                                    src={product.path ? `${process.env.REACT_APP_API_URL}/${product.path}` : ''}
                                                    alt=""/>
                                                <div className={'product_info_top'}>
                                                    <div className={'product_name'}>
                                                        <span className={'pr_name'}>{product.product_name}</span><br/>
                                                        <span className={'pr_brand'}>{product.brand_name}</span>
                                                    </div>
                                                    <span className={'pr_quantity'}>К-во: {product.quantity}</span>
                                                    {product.default_price ?
                                                        <span className={'pr_price'}>€{sum}</span> : null}
                                                </div>

                                                <div className={"product_info_bot"}>
                                                    {product?.properties?.map(property => {
                                                        return (
                                                            <div className={'product_prop'}>
                                                                <span>{property.property_name}</span>
                                                                <div className={'prop_info'}>
                                                                    <img
                                                                        src={property.parameter_icon ? `${process.env.REACT_APP_API_URL}/${property.parameter_icon}` : ""}
                                                                        alt=""/>
                                                                    <span>{property.parameter_name}</span>
                                                                    {property.value_added ?
                                                                        <span>+€{property.value_added}</span> : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className={'calc_price'}>
                                                <span>Рассчётная цена, €</span>
                                                <input onChange={e => {
                                                    popupPrices[index].price = +e.target.value;

                                                    setPopupPrices([...popupPrices])
                                                }} value={product.default_price ? popupPrices[index].price : null}
                                                       type="number"/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={'popup_comment'}>
                                <span>Комментарий</span>
                                <textarea onChange={(e) => {
                                    setPopoupComment(e.target.value)
                                }} placeholder={"Комментарий для клиента"} value={popupComment} rows="5"/>
                            </div>
                            <div className="popup_footer">
                                {/*<p className="g-modal__sum">Сума: ₴{modalData.total_price}</p>*/}
                                {modalData.sale ?
                                    <div className={'popup_footer_price'}>
                                        <span className={'ft_sale'}>Скидка {modalData.sale}%</span>
                                        <span
                                            className={'ft_price'}>Цена заказа со скидкой: €{modalData.total_price}</span>
                                    </div>
                                    :
                                    <span className={'ft_price'}>Цена заказа: €{modalData.total_price}</span>
                                }
                                <button className="g-modal__close g-add" style={{background: "#333333"}}
                                        onClick={handleModal}>
                                    Отправить расчёт
                                </button>
                            </div>
                        </div>
                    </div> : null}


                    <div className="orders-view">
                        <span className="orders-view__new">Заказы</span>
                        <button className={`orders-view__new${options.in_archive ? '' : ' active'}`}
                                onClick={handleTabNew}>Новые
                        </button>
                        <button className={`orders-view__old${options.in_archive ? ' active' : ''}`}
                                onClick={handleTabOld}>Архив
                        </button>
                    </div>

                    <table className="orders-table overflow_scroll admin_table">
                        <thead className="orders-table__head">
                        <tr className="orders-table__tr">
                            <th className="orders-table__th"/>
                            <th className="orders-table__th">Имя</th>
                            <th className="orders-table__th">Дата</th>
                            <th className="orders-table__th">Имейл</th>
                            <th className="orders-table__th">Товары</th>
                            <th className="orders-table__th">Промокод</th>
                            <th className="orders-table__th">Скидка</th>
                        </tr>
                        </thead>

                        <tbody className="orders-table__body ">
                        {ordersData.map((item) =>
                            <tr className="orders-table__tr" key={item.order_id}>
                                <td className="orders-table__td">
                                    <button className="g-btn__view" onClick={() => {
                                        handleModalData(item.order_id);
                                        setModal(!modal);
                                    }}>Сделать расчет
                                    </button>
                                </td>
                                <td className="orders-table__td">{item.username}</td>
                                <td className="orders-table__td">{item.date}</td>
                                <td className="orders-table__td">{item.user_email}</td>
                                <td className="orders-table__td">{item.number_of_products}</td>
                                <td className="orders-table__td">{item.promo_code_name ? item.promo_code_name : "-"}</td>
                                <td className="orders-table__td">{item.promo_code_sale ? item.promo_code_sale : "-"}</td>

                            </tr>
                        )}
                        </tbody>
                    </table>

                    {leftCount ?
                        <button className="g-add" onClick={!loading ? showMore : null}>Показать ещё</button> : null}
                </div>
            </section>
        </Layout>
    );
};

export default OrdersPage
