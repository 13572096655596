import React, {useEffect, useState} from 'react';
import API from "../../API";
import {Link} from "react-router-dom";
import './style/user_order.css'
import rectangle from '../../assets/rectangle_color.svg'

export default function UserOrder() {
    const [activeTab, setActiveTab] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)

    useEffect(() => {
        getActiveTab('1')
        window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    }, [])

    const getActiveTab = (request) => {
        setLoading(false)
        setActiveTab(request);
        API.get(`user/buy?current=${request}`)
            .then(res => {
                if (res.data) {
                    setData(res.data)
                    setLoading(true)
                }
            })
            .catch(err => console.log(err))
    }

    console.log(data)

    return (
        <div className={'user_order_wrapper'}>
            <div className={'user_order_wrapper_tabs_container'}>
                <h3 onClick={() => getActiveTab('1')}
                    className={`user_order_wrapper_tab ${activeTab === '1'
                        ? 'user_order_wrapper_tab_active' : ''}`}>Current orders</h3>
                <h3 onClick={() => getActiveTab(false)}
                    className={`user_order_wrapper_tab ${!activeTab
                        ? 'user_order_wrapper_tab_active' : ''}`}>All orders</h3>
            </div>
            {loading ? data.orders.length ?
                data.orders.map((item, index) => (
                    <div key={index} className={`user_order_item_container`}>
                        {item.products.map(product => (
                            innerWidth > 600 ?
                                <div key={product.ordered_product_id} className={'user_order_item_container_product'}>
                                    <div className={'user_order_item_container_product_image'}>
                                        <Link to={`/goods/${product.product_id}`}>
                                            <img
                                                src={product.path ? `${process.env.REACT_APP_API_URL}/${product.path}` : null}
                                                alt="product"/></Link>
                                    </div>
                                    <div>
                                        <h5>{product.brand_name}</h5>
                                        <Link to={`/goods/${product.product_id}`}>
                                            <h4>{product.product_name}</h4>
                                        </Link>
                                        <div className={'user_order_item_container_product_properties'}>
                                            {product.properties.length ? product.properties.map((property, index) => (
                                                <div className={'user_order_item_container_product_properties_item'}
                                                     key={index}>
                                                    <span>{property.property_name ? property.property_name : 'qwerty'}</span>
                                                    <div className={'user_order_item_container_product_properties_item_container'}>
                                                        <img
                                                            src={property.parameter_icon ? `${process.env.REACT_APP_API_URL}/${property.parameter_icon}` : rectangle}
                                                            alt="parameter icon"/>
                                                        <p>{property.property_name ? property.property_name : 'zxc'}</p>
                                                        {property.value_added
                                                            ? <span>+€{property.value_added}</span>
                                                            : <span>+€123</span>}
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div className={'user_order_item_container_product_price'}>
                                        {console.log(product.quantity)}
                                        <span>{product.price ? '€' + product.price : 'Quoted price'} x {product.quantity}</span>
                                    </div>
                                </div>
                                :
                                <div key={product.ordered_product_id}
                                     className={'user_order_item_container_product user_order_item_container_product_mobile'}>
                                    <div className={'user_order_item_container_product_image'}>
                                        <Link to={`/goods/${product.product_id}`}>
                                            <img
                                                src={product.path ? `${process.env.REACT_APP_API_URL}/${product.path}` : null}
                                                alt="product"/></Link>
                                    </div>
                                    <div>
                                        <h5>{product.brand_name}</h5>
                                        <Link to={`/goods/${product.product_id}`}>
                                            <h4>{product.product_name}</h4>
                                        </Link>
                                        <div className={'user_order_item_container_product_price'}>
                                            <span>{product.price ? '€' + product.price : 'Quoted price'} x {product.quantity}</span>
                                        </div>
                                    </div>
                                    <div
                                        className={'user_order_item_container_product_properties user_order_item_container_product_properties_mobile'}>
                                        {product.properties.length ? product.properties.map((property, index) => (
                                            <div
                                                className={'user_order_item_container_product_properties_item user_order_item_container_product_properties_item_mobile'}
                                                key={index}>
                                                <span>{property.property_name ?? null}</span>
                                                <div
                                                    className={'user_order_item_container_product_properties_item_container'}>
                                                    <img
                                                        src={property.parameter_icon ? `${process.env.REACT_APP_API_URL}/${property.parameter_icon}` : rectangle}
                                                        alt="parameter icon"/>
                                                    <p>{property.property_name ?? null}</p>
                                                    {property.value_added
                                                        ? <span>+€{property.value_added}</span>
                                                        : <span>+€123</span>}
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                        ))}
                        <div className={'user_order_item_container_product_footer'}>
                            {item.sale ?
                                <span>Discount: {item.sale}%</span> : null}
                            <div className={'user_order_item_container_product_footer_price'}>
                                <p>Final price:</p>
                                <p>{item.total_price ? '€' + item.total_price : 'the price is specified by shop'}</p>
                                <p>Status: {item.current ? 'In process' : 'Delivered'}</p>
                            </div>
                            {item.comment ?
                                <div>
                                    <small>Comment:</small>
                                    <p>{item.comment}</p>
                                </div>
                                : null}
                        </div>
                    </div>
                )) : <div className={'user_order_current_orders'}>
                    <span>{!activeTab ? 'There is no orders yet.' : 'There is no current orders.'}</span></div>
                : null
            }
            <div>
                {loading && activeTab === '1' && !data.orders.length ? <div className={'user_order_current_orders'}>
                    <hr/>
                    <div className={'user_order_current_orders_buttons'}>
                        <Link to={`/catalogue/popular_products`}>В каталог</Link>
                        <Link to={`/cart`}>Go to cart</Link>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}
