import React from 'react'
import './styles/footer.css'
import facebook_icon from '../assets/facebook_icon.svg'
import instagram_icon from '../assets/instagram_icon.svg'
import youtube_icon from '../assets/youtube_icon.svg'
import {Link} from "react-router-dom";

export default function Footer() {

    const returnEveryLinks = (link, value) => {
        return (
            <Link to={link} className={'open_sans_300'}>
                {value}
            </Link>
        )
    };

    return (
        <footer>
            <div className={'wrapper_content'}>
                <div className={'deluxe_mobili_info_wrapper wrapper_every_block'}>
                    <div className={'wrapper_info_description'}>
                        <span className={'playfair_display_400 logo'}>
                            Vinteriors
                        </span>
                        <span className={'open_sans_300 description'}>
                            Vinteriors предтавляет лучшие авторские дизайн проекты, премиум мебель и домашние аксессуары.
                        </span>
                    </div>
                    <div className={'wrapper_join_to_us_section'}>
                        <span className={'open_sans_400'}>Мы в соцсетях</span>
                        <div className={'wrapper_social_media'}>
                            <a href="https://www.google.com/">
                                <img src={facebook_icon} alt="facebook_icon"/>
                            </a>
                            <a href="https://www.google.com/">
                                <img src={instagram_icon} alt="instagram_icon"/>
                            </a>
                            <a href="https://www.google.com/">
                                <img src={youtube_icon} alt="youtube_icon"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'wrapper_every_block'}>
                    <span className={'open_sans_400 block_title'}>
                        Страницы
                    </span>
                    <div className={'wrapper_links'}>
                        {returnEveryLinks("/catalogue/popular_products?", 'Каталог')}
                        {returnEveryLinks("/terms/delivery", 'Условия')}
                        {returnEveryLinks("/about_company", 'О компании')}
                        {returnEveryLinks("/contacts", 'Контакты')}
                    </div>
                </div>
                {/*<div className={'wrapper_every_block'}>*/}
                {/*    <span className={'open_sans_400 block_title'}>*/}
                {/*        Информация*/}
                {/*    </span>*/}
                {/*    <div className={'wrapper_links'}>*/}
                {/*        {returnEveryLinks("https://www.google.com/", 'О нас')}*/}
                {/*        {returnEveryLinks("https://www.google.com/", 'Команда')}*/}
                {/*        {returnEveryLinks("https://www.google.com/", 'Партнеры')}*/}
                {/*        {returnEveryLinks("https://www.google.com/", 'Пресса')}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'wrapper_every_block'}>
                    <span className={'open_sans_400 block_title'}>
                       Важно
                    </span>
                    <div className={'wrapper_links'}>
                        {returnEveryLinks("/terms/delivery", 'Доставка')}
                        {returnEveryLinks("/terms/terms_and_conditions", 'Условия использования')}
                        {returnEveryLinks("/terms/privacy_policy", 'Политика приватности')}
                        {returnEveryLinks("/terms/cookies", 'Куки')}
                    </div>
                </div>
                <div className={'wrapper_every_block'}>
                    <span className={'open_sans_400 block_title'}>
                       Другие наши услуги
                    </span>
                    <div className={'wrapper_links'}>
                        <button onClick={() => window.open('https://vesperinteriors.ru/contacts')} className={'request-design-button'}>Заказать дизайн <br/>интерьера</button>
                    </div>
                </div>
                <div className={'wrapper_every_block mobile_version'}>
                    <span className={'open_sans_400 block_title'}>Подпишитесь на нас</span>
                    <div className={'wrapper_social_media'}>
                        <a href="https://www.google.com/">
                            <img src={facebook_icon} alt="facebook_icon"/>
                        </a>
                        <a href="https://www.google.com/">
                            <img src={instagram_icon} alt="instagram_icon"/>
                        </a>
                        <a href="https://www.google.com/">
                            <img src={youtube_icon} alt="youtube_icon"/>
                        </a>
                    </div>
                </div>
                <div className={'wrapper_all_rights_reserved'}>
                    <span className={'roboto_400'}>
                        ©Vinteriors. Все права зарезервированы.
                    </span>
                </div>
            </div>
        </footer>
    )
}
