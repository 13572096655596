import React from 'react';
import {forceLogout} from '../API'

export default function PageTitle({title, logout}) {
    return (
        <div className={`page_title ${logout ? 'page_title_logout' : ''}`}>
            <span className={'playfair_display_400'}>{title}</span>
            {logout ?
                <p onClick={() => forceLogout()}>Выйти</p> : null}
        </div>
    )
}
