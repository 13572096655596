import React from 'react';
import icon_loop from '../assets/icon_loop.svg'

const CatalogFindItems = ({text}) => {
    return (
        <div className={'wrapper_catalog_find_items'}>
            <img src={icon_loop} alt="icon_loop"/>
            <span>
                {text ?? 'Нет товаров, подходящих выбранным фильтрам'}
            </span>
        </div>
    )
}

export default CatalogFindItems;