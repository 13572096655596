import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function CookiesPage(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Куки — Vinteriors store.'}/>
                <title>Куки — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Куки — Vinteriors store.'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <span className={'playfair_display_400'}>
                <h2 className={'title'}>Политика использования и хранения куки-файлов</h2>
            </span>
        </div>
    )
}
