import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Link, Route} from "react-router-dom";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";
import Pagination from "react-js-pagination";

import BookItem from "../adminComponents/BookItem"

import "../adminComponents/style/goods.scss"

import AddIcon from "../assets/g-icon-add.svg"
import IconArrowDown from "../assets/g-icon-arrow_down.svg"
import IconArrowRight from "../assets/g-icon-arrow_right.svg"
import SearchIcon from "../assets/g-icon-search.svg";
import {connect} from 'react-redux'
import {changeActiveBrand} from '../main-store/brand/actions'
import vector from "../assets/vector.svg";

const GoodsPage = ({match, activeBrand}) => {
    const [addItem, setAddItem] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [pagination, setPagination] = useState(
        {
            total: null,
            pages: null,
            pageCount: 36,
            activePage: 1
        });

    const [booksData, setBooksData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);

    const [requestList, setRequestList] = useState(false);
    //
    // const [showCount, setShowCount] = useState(0);
    // const [totalCount, setTotalCount] = useState(0);
    // const [leftCount, setLeftCount] = useState(0);

    const [loading, setLoading] = useState(false);

    const [bookID, setBookID] = useState(undefined);

    const ref = useRef();

    const checkUrl = useCallback(() => {
        const urlArr = match.url.split("/");

        if (activeBrand) {
            API.get(`/admin/products_by_brand`, {params: {brand_id: activeBrand, per_page: 36}})
                .then(res => {
                    console.log(res);
                    setBooksData(res.data.products);
                    // setTotalCount(res.data.total);
                    // setLeftCount(res.data.total - res.data.products.length);
                    setLoading(false);
                    setAddItem(false);
                })
        } else {
            if (urlArr[3] === "all") {
                setLoading(true);
                API.get("/admin/all_products", {params: {page: pagination.activePage}})
                    .then(res => {
                        console.log(res)
                        setPagination(state => ({...state, total: res.data.total, pages: res.data.pages, per_page: 36}))
                        setBooksData(res.data.products);
                        // setTotalCount(res.data.total);
                        // setLeftCount(res.data.total - res.data.products.length);
                        setLoading(false);
                        setAddItem(false);
                    });

            } else if (urlArr[3] !== "all" && urlArr.length === 4) {
                setLoading(true);
                API.get("/admin/all_products", {params: {category_id: +urlArr[3], page: pagination.activePage, per_page: 36}})
                    .then(res => {
                        console.log(res)
                        setPagination(state => ({...state, total: res.data.total, pages: res.data.pages}))
                        setBooksData(res.data.products);
                        // setTotalCount(res.data.total);
                        // setLeftCount(res.data.total - res.data.products.length);
                        setLoading(false);
                        setAddItem(false);
                    });
            } else if (urlArr[3] !== "all" && urlArr.length === 5) {
                setLoading(true);
                API.get("/admin/all_products", {params: {subcategory_id: +urlArr[4], page: pagination.activePage, per_page: 36}})
                    .then(res => {
                        setPagination(state => ({...state, total: res.data.total, pages: res.data.pages}))
                        console.log(res)
                        setBooksData(res.data.products);
                        // setTotalCount(res.data.total);
                        // setLeftCount(res.data.total - res.data.products.length);
                        setLoading(false);
                        setAddItem(true);
                    });
            }
        }
    }, [match.url, pagination.activePage, activeBrand])

    const [catalogSearch, setCatalogSearch] = useState("");
    const [catalogSearchData, setCatalogSearchData] = useState([]);


    useEffect(() => {
        API.get("/admin/categories")
            .then(res => {
                setCategoriesData(res.data.categories);
            });

        checkUrl();

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [checkUrl]);

    const handleRequestList = () => {
        setRequestList(!requestList);
        setCatalogSearchData([]);
        setCatalogSearch("");
    };

    const handleClickOutside = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            setRequestList(false);
        }
    };

    const HandleTitle = ({match, data}) => {

        const {
            params: {categoryId, subcategoryId}
        } = match;

        const category = data.find(({id}) => id === +categoryId);

        if (category && subcategoryId !== undefined && category.subcategories) {
            const subcategory = category.subcategories.find(({id}) => id === +subcategoryId);

            return (
                <h2 className="g-title">{category.name}/{subcategory.name}</h2>
            );
        } else if (category) {
            return (
                <h2 className="g-title">{category.name}</h2>
            );
        } else {
            return (
                <h2 className="g-title">Выбрать категорию</h2>
            );
        }
    };

    const changeMode = modeStatus => {
        if (addMode) {
            setAddMode(modeStatus);
        } else {
            setEditMode(modeStatus);
        }
    };

    const updateData = () => {
        checkUrl();
        window.scrollTo(0, 0);
    };

    const handleSearch = e => {
        e.preventDefault();
        setCatalogSearch(e.target.value);

        if (e.target.value.length) {

            const checkSearchUrl = () => {
                const searchUrlArr = match.url.split("/");
                if (searchUrlArr[3] === "all") {
                    API.get(`/admin/search`, {params: {name: e.target.value}})
                        .then(res => {
                            setCatalogSearchData(res.data.products);
                        })
                } else if (searchUrlArr[3] !== "all" && searchUrlArr.length === 4) {
                    API.get(`/admin/search`, {params: {name: e.target.value, category: +searchUrlArr[3]}})
                        .then(res => {
                            setCatalogSearchData(res.data.products);
                        })
                } else if (searchUrlArr[3] !== "all" && searchUrlArr.length === 5) {
                    API.get(`/admin/search`, {params: {name: e.target.value, subcategory: +searchUrlArr[4]}})
                        .then(res => {
                            setCatalogSearchData(res.data.products);
                        })
                }
            };

            checkSearchUrl();
        } else {
            setCatalogSearchData([])
        }
    };

    return (
        <Layout>
            <section className="goods">
                <div className="container">
                    {addMode || editMode ?
                        <BookItem id={bookID} addMode={addMode} editMode={editMode} subId={+match.url.split("/")[4]}
                                  categoryId={+match.url.split("/")[3]} changeFunction={changeMode}
                                  updateData={updateData}/>
                        :
                        <div className="goods__block">
                            <div className="goods-headline">
                                <div ref={ref} className={`g-select${requestList ? ` g-select__open` : ''}`}>
                                    <div className="g-select-header" onClick={handleRequestList}>

                                        <Route path="/admin/goods/:categoryId/:subcategoryId?"
                                               render={props => <HandleTitle data={categoriesData} {...props} />}/>

                                        <img className="g-select-header__arrow" src={IconArrowDown} alt="Категорія"/>
                                    </div>

                                    {requestList ?
                                        <div className="g-select_absolute">
                                            <ul className="g-select-list">
                                                {categoriesData.map((category) => {
                                                    return (
                                                        <li className="g-select-list__item" key={category.id}>
                                                            <Link className="g-select-list__link"
                                                                  to={`/admin/goods/${category.id}`}
                                                                  onClick={handleRequestList}>{category.name}</Link>

                                                            <div className="g-select-sub">
                                                                {category.subcategories.map(subcategory => <Link
                                                                    className="g-select-sub__link" key={subcategory.id}
                                                                    to={`/admin/goods/${category.id}/${subcategory.id}`}
                                                                    onClick={handleRequestList}>{subcategory.name}</Link>
                                                                )}
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            <Link className="g-select__all" to="/admin/goods/all"
                                                  onClick={handleRequestList}>Все разделы
                                                <img className="g-select__arrow_right" src={IconArrowRight} alt=""/>
                                            </Link>
                                        </div> : null}
                                </div>
                                {addItem ?
                                    <button className="g-add" onClick={() => setAddMode(true)}>
                                        <img className="g-add__icon" src={AddIcon} alt="Добавить"/>
                                        Добавить товар
                                    </button>
                                    : <span/>}

                                <form className="g-search">
                                    <input type="search" className="g-search__input" placeholder="Поиск"
                                           value={catalogSearch} onChange={handleSearch}/>

                                    <button className="g-search__button">
                                        <img className="g-search__icon" src={SearchIcon} alt="Поиск"/>
                                    </button>
                                    {loading && catalogSearchData.length ? <ul className="g-search-list">
                                        {catalogSearchData.map(item =>
                                            <li className="g-search-list__item" key={item.id} onClick={() => {
                                                setCatalogSearchData([]);
                                                setCatalogSearch("");
                                                setBookID(item.id);
                                                setEditMode(true);
                                            }}>{item.name}</li>
                                        )}
                                    </ul> : null}
                                </form>
                            </div>

                            <div className="goods__wrapper">
                                {booksData && booksData.length > 0 ? booksData.map(item =>
                                    <div className="goods-item" key={item.id} onClick={() => {
                                        setBookID(item.id);
                                        setEditMode(true);
                                    }}>
                                        <div className="goods-item__source">
                                            <img className="goods-item__image"
                                                 src={item.path ? `${process.env.REACT_APP_API_URL}/${item.path}` : ""}
                                                 alt=""/>
                                        </div>

                                        <div className="goods-item__block">
                                            <h6 className="goods-item__title">{item.name}</h6>
                                            <p className="goods-item__author">{item.brand}</p>

                                        </div>
                                    </div>
                                ) : null}

                            </div>
                            {pagination.total ?
                                <div className={'wrapper_pagination'}>
                                    <Pagination
                                        activePage={Number(pagination.activePage)}
                                        itemsCountPerPage={pagination.pageCount}
                                        // firstPageText={activePage >= 3 && goods.pages > 3 ? '1' : null}
                                        prevPageText={<img src={vector} className={'prev_page_pagination'}
                                                           alt="vector"/>}
                                        nextPageText={<img src={vector} className={'next_page_pagination'}
                                                           alt="vector"/>}
                                        totalItemsCount={pagination.total}
                                        // lastPageText={activePage <= goods.pages - 2 && goods.pages > 3 ? goods.pages.toString() : null}
                                        pageRangeDisplayed={5}
                                        onChange={pageNumber => setPagination(state => ({
                                            ...state,
                                            activePage: pageNumber
                                        }))}/>
                                </div>
                                : null}
                            {/*{leftCount ? <button className="g-add" onClick={!loading ? showMore : null}>Показать*/}
                            {/*    ещё</button> : null}*/}
                        </div>}
                </div>

            </section>

        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        activeBrand: state.shop.activeBrand
    }
};

const putStateToProps = {
    changeActiveBrand
}

export default connect(mapStateToProps, putStateToProps)(GoodsPage)
