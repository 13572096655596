import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function PrivacyPolicy() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Политика приватности — Vinteriors store.'}/>
                <title>Политика приватности — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Политика приватности — Vinteriors store.'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <span className={'playfair_display_400'}>
                <h2 className={'title'}>Политика приватности</h2>
                <h3 className={'subtitle'}>Политика обработки данных</h3>
                <p className={'content_other'}>{'1. Общие условия'}</p>
                <p className={'content'}>{'1.1. Политика обработки персональных данных (далее - Политика) направлена на защиту прав и свобод лиц, чьи персональные данные обрабатываются на сайте https://vesperinteriors.ru/ (далее - Оператор).'}</p>
                <p className={'content'}>{'1.2. Политика разработана в соответствии с п. 2 ч. 1 п. 18.1 Федерального закона от 27 июля 2006 года. 152-ФЗ "О персональных данных" (далее - Федеральный закон "О персональных данных").'}</p>
                <p className={'content'}>{'1.3. Политика содержит информацию, которая подлежит раскрытию в соответствии с параграфом 1 статьи. 14 Федерального закона "О персональных данных" и является публичным документом.'}</p>
                <p className={'content_other'}>{'2. Информация об операторе'}</p>
                <p className={'content'}>{'2.1. Оператор работает по адресу 107140, г. Москва, 1-й Красносельский перекресток, 3.'}</p>
                <p className={'content_other'}>{'З. Информация об обработке персональных данных'}</p>
                <p className={'content'}>{`3.1. Оператор обрабатывает персональные данные на законных и справедливых основаниях для выполнения функций, полномочий и обязательств, установленных законодательством, осуществляя права и законные интересы оператора, работников оператора и третьих лиц.`}</p>
                <p className={'content'}>{'3.2. Оператор получает персональные данные непосредственно от субъектов персональных данных (далее - ПД).'}</p>
                <p className={'content'}>{'3.3. Оператор обрабатывает персональные данные автоматизированным и неавтоматизированным способом, используя компьютерные средства и без использования таких средств.'}</p>
                <p className={'content'}>{'3.4. Действия по обработке персональных данных включают сбор, регистрацию, систематизацию, накопление, хранение, уточнение (обновление, модификацию), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.'}</p>
                <p className={'content_other'}>{'4. Обработка персональных данных клиентов'}</p>
                <p className={'content'}>{'4.1. Оператор обрабатывает персональные данные своих клиентов в рамках правоотношений с Оператором, регулируемых частью второй Гражданского кодекса РФ от 26 января 1996 года N2 14-ФЗ (далее - клиенты).'}</p>
                <p className={'content'}>{'4.2. Оператор обрабатывает персональные данные своих клиентов с целью соблюдения требований российского законодательства, а также для этих целей:'}</p>
                <p className={'content'}>{'- Принятие апелляций и заявлений от субъекта ПД;'}</p>
                <p className={'content'}>{'- информировать о новых товарах, специальных акциях и предложениях;'}</p>
                <p className={'content'}>{'- заключать и исполнять договор.'}</p>
                <p className={'content'}>{'4.3. Оператор обрабатывает персональные данные клиентов с их согласия, предоставленного клиентами и/или их законными представителями, выполняя действия на данном сайте, в том числе, но не ограничиваясь этим, делая заказ, регистрируясь в личном кабинете, подписываясь на рассылку, в соответствии с данной Политикой.'}</p>
                <p className={'content'}>{'4.4. Оператор обязуется обрабатывать персональные данные клиентов не дольше, чем это требуется для целей обработки персональных данных, если иное не предусмотрено российским законодательством.'}</p>
                <p className={'content'}>{'4.5. Оператор может обрабатывать следующие личные данные клиентов:'}</p>
                <p className={'content'}>{'— Полное имя;'}</p>
                <p className={'content'}>{'— Адрес;'}</p>
                <p className={'content'}>{'— Контактный номер телефона;'}</p>
                <p className={'content'}>{'— Адрес электронной почты.'}</p>
                <p className={'content'}>{'4.6. Специальные категории персональных данных не обрабатываются:'}</p>
                <p className={'content'}>{'4.6.1. касающиеся расы, национальности;'}</p>
                <p className={'content'}>{'4.6.2. политические взгляды, религиозные или философские убеждения;'}</p>
                <p className={'content'}>{'4.6.3. здоровье и личную жизнь.'}</p>
                <p className={'content_other'}>{'5. Информация о безопасности персональных данных'}</p>
                <p className={'content'}>{'5.1. Оператор при обработке персональных данных принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от незаконного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от других незаконных действий в отношении персональных данных.'}</p>
                <p className={'content'}>{'5.2. Меры по обеспечению безопасности персональных данных при их обработке, применяемые оператором, планируются и реализуются в целях соблюдения требований, установленных статьей 19 Федерального закона-152 "О персональных данных".'}</p>
                <p className={'content'}>{'5.3. В соответствии со статьей 18.1 ФЗ-152 Оператор самостоятельно определяет состав и перечень мероприятий, необходимых и достаточных для обеспечения соблюдения требований законодательства. В частности, Оператор принимает следующие меры:'}</p>
                <p className={'content'}>{'- назначено лицо, ответственное за организацию обработки ПД;'}</p>
                <p className={'content'}>{'- Разработаны и введены в действие локальные акты по вопросам рассмотрения ПД, а также локальные акты, устанавливающие порядок предупреждения и выявления нарушений установленного порядка рассмотрения ПД и устранения последствий таких нарушений;'}</p>
                <p className={'content'}>{'- правовые, организационные и технические меры по обеспечению безопасности ЭДН применяются в соответствии со статьей 19 Федерального закона-152; - осуществляется внутренний контроль за соответствием обработки ЭДН требованиям по защите ЭДН, политике Оператора в отношении обработки ЭДН, локальным актам Оператора;'}</p>
                <p className={'content'}>{'- оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Федерального закона-152, соотношение указанного вреда и мер, принятых оператором, направленных на обеспечение выполнения обязанностей, предусмотренных Федеральным законом-152;'}</p>
                <p className={'content'}>{`- Операторы, непосредственно осуществляющие ПД, ознакомлены с положениями законодательства Российской Федерации о ПД, в том числе с требованиями к защите ПД, документами, определяющими политику Оператора в отношении обработки ПД, локальными актами по обработке ПД;`}</p>
                <p className={'content'}>{'- В дополнение к требованиям 152-ФЗ "О персональных данных", Оператор имеет комплекс мер, направленных на защиту информации о клиентах, сотрудниках и контрагентах.'}</p>
                <p className={'content_other'}>{'6. Права субъектов персональных данных'}</p>
                <p className={'content'}>{'6.1. Субъект персональных данных имеет право:'}</p>
                <p className={'content'}>{'- получать персональные данные, связанные с этой темой, и информацию, связанную с их обработкой;'}</p>
                <p className={'content'}>{'- к уточнению, блокированию или уничтожению его персональных данных, если они являются неполными, устаревшими, неточными, полученными незаконным путем или не необходимыми для заявленной цели обработки;'}</p>
                <p className={'content'}>{'- к отзыву согласия, данного им на обработку персональных данных;'}</p>
                <p className={'content'}>{'- к защите его прав и законных интересов, в том числе к возмещению убытков и компенсации морального ущерба в судебном порядке;'}</p>
                <p className={'content'}>{`- обжаловать действия или бездействие оператора в уполномоченном органе по защите прав субъектов персональных данных или в суде.`}</p>
                <p className={'content'}>{'6.2. В целях реализации своих прав и законных интересов субъекты персональных данных имеют право обратиться к Оператору или направить запрос лично или с помощью представителя. Запрос должен содержать информацию, указанную в части Z статьи 14 Федерального закона "О персональных данных".'}</p>
            </span>
        </div>
    )
}
