import React, {useState} from 'react'
import vector from '../assets/vector.svg';

export default function SortBy({activeSortBy, changeActiveSortBy}) {
    const [activeDropDown, changeDropDown] = useState(false);

    let sortBy = [
        {
            variant: 'Popular',
            request_variant: null,
        },
        {
            variant: 'Price up',
            request_variant: 'price_asc',
        },
        {
            variant: 'Price down',
            request_variant: 'price_desc',
        },
    ];

    return (
        <div className={'wrapper_sort_by'}>
            <span className={'playfair_display_400 sort_by_text'}>
               Сортировать по
            </span>
            <div className={'wrapper_sort_by_block'}>
                <div className={'sort_by_block'}
                     onClick={() => changeDropDown(!activeDropDown)}>
                    <span className={'roboto_400'}>{activeSortBy.variant}</span>
                    <img src={vector} className={activeDropDown ? 'active_image' : null} alt="vector"/>
                </div>
                {activeDropDown &&
                <div className="wrapper_drop_down">
                    {sortBy.map((item, index) => {
                        return activeSortBy.variant !== item.variant ?
                            <div className={'wrapper_every_sort'}
                                 key={index}
                                 onClick={() => {
                                     changeActiveSortBy(item);
                                     changeDropDown(false)
                                 }}>
                                <span>{item.variant}</span>
                            </div>
                            : null
                    })}
                </div>
                }
            </div>
        </div>
    )
}
