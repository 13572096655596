import React, {useEffect, useState, Fragment, useMemo, useRef,} from 'react'
import './styles/filter.css'
import vector from "../assets/vector.svg"
import white_delete from "../assets/white_delete.svg"
import Input from "./Input"
import CheckBox from "./CheckBox"
import {ReactiveBase, RangeInput} from '@appbaseio/reactivesearch'
import API from "../API";

const CatalogFilters = React.memo(({filters_data, getFilters, ...props}) => {
    const [activeCategory, changeActiveCategory] = useState(null);
    const [priceValue, changePriceValue] = useState({min: 0, max: filters_data.max_price});
    const [activeFilter, setActiveFilter] = useState([]);
    const [requestUrl, setRequestUrl] = useState(null);
    const [activeSubcategory, setActiveSubcategory] = useState(null);
    const prevRequestUrl = useRef();

    const debouncedSearchTermMin = useDebounce(priceValue.min, 200);
    const debouncedSearchTermMax = useDebounce(priceValue.max, 200);


    useEffect(() => {
        if (filters_data?.filters?.length) {
            resetFilters();
            changeActiveCategory(null)
        }
        // eslint-disable-next-line
    }, []);

    function useDebounce(value, delay) {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                // Update debounced value after delay
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);

                // Cancel the timeout if value changes (also on delay change or unmount)
                // This is how we prevent debounced value from updating if value is changed ...
                // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay] // Only re-call effect if value or delay changes
        );

        return debouncedValue;
    }

    let handleFilterChecked = (array, id) => {
        return array.filter(elem => elem.id !== id)
    };

    let handleDeleteCheck = id => {
        let newArr = handleFilterChecked(activeFilter, id);
        let protoCategory = filters_data;
        protoCategory.filters.forEach((item, index) => {
            if (activeCategory === index) {
                item.params.forEach(pre_category => {
                    if (pre_category.id === id) {
                        pre_category.checked = false;
                    }
                })
            }
        });
        getFilters({...protoCategory});
        setActiveFilter([...newArr]);
    };

    useEffect(() => {
        console.log(props.activePage);
    }, [props.activePage])

    let checkedPreCategoryValue = pre_category_id => {
        let protoCategory = filters_data;
        protoCategory.filters.forEach((item, index) => {
            if (activeCategory === index) {
                item.params.forEach(pre_category => {
                    if (pre_category.id === pre_category_id) {
                        pre_category.checked = !pre_category.checked;
                        if (pre_category.checked) {
                            setActiveFilter(prev => [...prev,
                                {
                                    name: pre_category.name,
                                    id: pre_category.id,
                                    brands: item.name === 'Brand'
                                }
                            ])
                        } else {
                            let newArr = handleFilterChecked(activeFilter, pre_category_id);
                            setActiveFilter([...newArr])
                        }
                    }
                })
            }
        });
        getFilters({...protoCategory});
    };

    let searchPreCategory = ({target}) => {
        let protoCategory = filters_data;
        protoCategory.filters.forEach((item, index) => {
            if (activeCategory === index) {
                item.input_value = target.value
            }
        });
        getFilters({...protoCategory});
    };

    let searchFilter = category_obj => {
        return category_obj.params.filter(item => {
            if (category_obj.input_placeholder) {
                if (item.name.toLowerCase().includes(category_obj.input_value.toLowerCase())) {
                    return item
                } else {
                    return null
                }
            } else {
                return item
            }
        });
    };

    let preCategoryWrapper = category_obj => {
        return (
            <div className={'wrapper_every_pre_category'}>
                {category_obj.input_placeholder ?
                    <div className={'wrapper_every_search_input'}>
                        <Input placeholder={category_obj.input_placeholder}
                               handleChange={searchPreCategory}
                               value={category_obj.input_value}/>
                    </div>
                    : null}
                <div className={'wrapper_pre_category_array overflow_scroll'}>
                    {searchFilter(category_obj).map(item => {
                        return (
                            <div className="every_pre_category" key={item.id}>
                                <CheckBox text={item.name}
                                          handleChange={() => checkedPreCategoryValue(item.id)}
                                          value={item.checked}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };

    let brandsArray = useMemo(() => {
        let brandIds = [];
        activeFilter.forEach(item => {
            if (item.brands) {
                brandIds.push(item.id)
            }
        });
        return brandIds
    }, [activeFilter]);

    let filtersArray = useMemo(() => {
        let filterIds = [];
        activeFilter.forEach(item => {
            if (!item.brands) {
                filterIds.push(item.id)
            }
        });
        return filterIds
    }, [activeFilter]);

    let getTagsValue = useMemo(() => {
        let tags_value = null;
        filters_data.category.subcategories.forEach(item => {
            if (item.id === activeSubcategory) {
                item.tags.forEach(tags => {
                    if (tags.checked) {
                        tags_value = tags.id;
                    }
                })
            }
        });
        props.setActivePage('1')
        return tags_value;
        // eslint-disable-next-line
    }, [filters_data]);


    useEffect(() => {
        prevRequestUrl.current = requestUrl;
    });


    useEffect(() => {
        changePriceValue(state => ({...state, max: filters_data?.max_price}));
    }, [filters_data])

    // filter request
    useEffect(() => {
        let sortBy = props.activeSortBy.request_variant ? '&order_by=' + props.activeSortBy.request_variant : null;
        let price;
        if (debouncedSearchTermMin ?? filters_data?.max_price !== debouncedSearchTermMax) {
            price = priceValue ? `&min_price=${debouncedSearchTermMin}&max_price=${debouncedSearchTermMax}` : null;
        }
        let brandsFilter = brandsArray.length ? '&brands=' + brandsArray : null;
        let tag_id = getTagsValue ? '&tag_id=' + getTagsValue : null;
        let anotherFilters = filtersArray.length ? '&filters=' + filtersArray : null;
        let requestUrl = `/products?${props.subcategoryId ? 'subcategory_id=' + props.subcategoryId : 'category_id=' + props.categoryId}` +
            `&page=${props.activePage}${price ?? ''}${sortBy ?? ''}${tag_id ?? ''}${brandsFilter ?? ''}${anotherFilters ?? ''}`;
        setRequestUrl(requestUrl);
        // console.log(prevRequestUrl.current, '______', requestUrl);
        if (prevRequestUrl.current !== requestUrl) {
            API.get(requestUrl)
                .then(res => {
                    props.setGoods({...res.data})
                });
        }
        // eslint-disable-next-line
    }, [activeFilter, brandsArray, props.activePage, filtersArray, getTagsValue, props.subcategoryId, debouncedSearchTermMin, debouncedSearchTermMax]);
    // filter request

    let resetFilters = () => {
        changePriceValue({min: 0, max: filters_data.max_price});
        let protoCategory = filters_data;
        protoCategory.filters.forEach(item => {
            item.params.forEach(pre_category => {
                if (pre_category.checked) {
                    pre_category.checked = false;
                }
            })
        });
        getFilters({...protoCategory});
        setActiveFilter([]);
    };

    let changeRangeValue = val => {
        if (val.start < filters_data.max_price && val.end > val.start) {
            changePriceValue({min: val.start, max: val.end});
        }
    };

    let everyCategoryWrapper = item => {
        return (
            <div className={'wrapper_every_category'}>
                {item.map((filter, index) => {
                    return (
                        <Fragment key={index}>
                            <div className={'every_category_header'}
                                 onClick={() => activeCategory === index ? changeActiveCategory(null) : changeActiveCategory(index)}>
                                <span className={'playfair_display_400'}>
                                    {filter.name_ru}
                                </span>
                                <img src={vector} className={activeCategory === index ? 'active-image' : ''}
                                     alt="vector"/>
                            </div>
                            {activeCategory === index ?
                                preCategoryWrapper(filter)
                                : null}
                        </Fragment>
                    )
                })}
            </div>
        )
    };

    let setActiveTags = (subcategory_id, id) => {
        let protoCategory = filters_data;
        protoCategory.category.subcategories.forEach(item => {
            if (item.id === subcategory_id) {
                item.tags.forEach(tags => {
                    if (tags.id === id) {
                        tags.checked = !tags.checked;
                    } else {
                        tags.checked = false;
                    }
                })
            } else {
                item.tags.forEach(tags => tags.checked = false)
            }
        });
        getFilters({...protoCategory});
    };

    return (
        <div className={`filter ${props.className}`}>
            <div className={'wrapper_every_category'}>
                <div className={'every_category_header'}
                     onClick={() => {
                         activeCategory === filters_data.category.name ?
                             changeActiveCategory(null) :
                             changeActiveCategory(filters_data.category.name)
                     }}>
                    <span className={'playfair_display_400'}>
                        Категории
                    </span>
                    <img src={vector}
                         className={activeCategory === filters_data.category.name ?
                             'active-image' :
                             ''}
                         alt="vector"/>
                </div>
                {activeCategory === filters_data.category.name ?
                    <div className={'wrapper_every_pre_category'}>
                        <div className={'wrapper_every_pre_category__name'}>
                            <span className={'wrapper_every_pre_category__name_text'}>Все категории</span>
                            <span
                                className={'wrapper_every_pre_category__name_text pl12'}>
                                {filters_data?.category?.name_ru}
                            </span>
                            {filters_data.category.subcategories.map(subcategory => {
                                return (
                                    <Fragment key={subcategory.id}>
                                        <span className={'wrapper_every_pre_category__name_link pl24'}
                                              onClick={() => {
                                                  if (subcategory.id === activeSubcategory) {
                                                      setActiveSubcategory(null)
                                                  } else {
                                                      setActiveSubcategory(subcategory.id)
                                                  }
                                              }
                                              }>
                                            {subcategory.name_ru}
                                        </span>
                                        {subcategory.id === activeSubcategory ?
                                            <div className={'wrapper_pre_category_array overflow_scroll'}>
                                                {subcategory.tags.map(item => {
                                                    return (
                                                        <div className="every_pre_category" key={item.id}>
                                                        <span className={item.checked ?
                                                            'category_tags_active category_tags open_sans_300' :
                                                            'category_tags open_sans_300'}
                                                              onClick={() => setActiveTags(subcategory.id, item.id)}>
                                                            {item.name}
                                                        </span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            : null}
                                    </Fragment>
                                )
                            })}
                        </div>

                    </div>
                    : null}
            </div>
            {filters_data?.max_price ?
                <ReactiveBase app="good-books-ds" credentials="nY6NNTZZ6:27b76b9f-18ea-456c-bc5e-3a5263ebc63d">
                    <div className="wrapper_price_title">
                        <span className={'playfair_display_400'}>Цена</span>
                    </div>
                    <RangeInput
                        className={"range_input"}
                        dataField="ratings_count"
                        componentId="BookSensor"
                        range={{
                            start: filters_data.min_price,
                            end: filters_data.max_price,
                        }}
                        defaultValue={{
                            start: priceValue.min,
                            end: priceValue.max,
                        }}
                        stepValue={1000}
                        snap={false}
                        onValueChange={changeRangeValue}
                        style={{}}/>
                </ReactiveBase>
                : null}
            {everyCategoryWrapper(filters_data.filters)}
            {activeFilter.length ?
                <div className="wrapper_active_filters">
                    {activeFilter.map(item => {
                        return (
                            <div className="every_active_filter" key={item.id}>
                                <span className={'open_sans_400'}>{item.name}</span>
                                <img src={white_delete}
                                     onClick={() => handleDeleteCheck(item.id)}
                                     alt={'white_delete'}/>
                            </div>
                        )
                    })}
                </div>
                : null}
            <div className="wrapper_reset_filters">
                <span className={'open_sans_400'} onClick={resetFilters}>
                    Сбросить фильтры
                </span>
            </div>
        </div>
    )
});
export default CatalogFilters;
