import React from "react";
import Landing from "../Pages/Landing";
import UserProfile from "../Pages/UserProfile/UserProfile";
import UniqueGoods from "../Pages/UniqueGoods";
import UserCart from "../Pages/UserCart";
import UserDeliveryAddress from "../Pages/UserProfile/UserDeliveryAddress";
import CataloguePage from "../Pages/CataloguePage";
import BrandsPage from "../Pages/BrandsPage";
import UserData from "../Pages/UserProfile/UserData";
import AboutCompany from "../Pages/AboutCompany";
import TermsMain from "../Pages/TermsMain";
import UserOrder from "../Pages/UserProfile/UserOrder";
import CookiesPage from "../Pages/CookiesPage";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import FavoriteGoods from "../Pages/FavoriteGoods";
import TermsAndConditions from "../Pages/TermsAndConditions";
import CatalogueList from "../Pages/CatalogueList";
import DeliveryPage from "../Pages/DeliveryPage";
import ContactsPage from "../Pages/ContactsPage";
import BrandsCatalogue from "../Pages/BrandsCatalogue"

export const Navigation = {
    headerNavigations: [
        {
            pathname: "/",
            main: () => <Landing/>,
        },
        {
            pathname: "/user_profile/:routes",
            main: () => <UserProfile/>,
        },
        {
            pathname: "/category_:id/subcategory_:id",
            main: () => <CataloguePage/>,
        },
        {
            pathname: "/category_:id",
            main: () => <CataloguePage/>,
        },
        {
            pathname: "/brands",
            main: () => <BrandsPage/>,
        },
        {
            pathname: "/catalogue/:routes",
            main: () => <CatalogueList/>,
        },
        {
            pathname: "/favorite_goods",
            main: () => <FavoriteGoods/>,
        },
        {
            pathname: "/terms/:routes",
            main: () => <TermsMain/>,
        },
        {
            pathname: "/goods/:id",
            main: () => <UniqueGoods/>,
        },
        {
            pathname: "/cart",
            main: () => <UserCart/>,
        },
        {
            pathname: "/about_company",
            main: () => <AboutCompany/>,
        },
        {
            pathname: "/contacts",
            main: () => <ContactsPage/>,
        },
        {
            pathname: "/brand/:id",
            main: () => <BrandsCatalogue/>
        }
    ],
    profileNavigations: [
        {
            pathname: "/user_profile/user_orders",
            label: "Мои заказы",
            main: () => <UserOrder/>
        },

        {
            pathname: "/user_profile/user_data",
            label: "Мои данные",
            main: () => <UserData/>,
        },
        {
            pathname: "/user_profile/user_address",
            label: "Адрес доставки",
            main: () => <UserDeliveryAddress/>
        },
    ],
    termsNavigations: [
        {
            pathname: "/terms/delivery",
            label: "Доставка",
            main: () => <DeliveryPage/>
        },
        {
            pathname: "/terms/terms_and_conditions",
            label: "Условия использования",
            main: () => <TermsAndConditions/>
        },
        {
            pathname: "/terms/privacy_policy",
            label: "Политика приватности",
            main: () => <PrivacyPolicy/>,
        },
        {
            pathname: "/terms/cookies",
            label: "Куки",
            main: () => <CookiesPage/>
        },
    ]
};

