import React, {useEffect, useState} from 'react'
import PageTitle from "../Components/PageTitle"
import SortBy from "../Components/SortBy"
import "./styles/catalog_page.css"
import {ScrollToTop} from "../Constants/ScrollToTop"
import CatalogFilters from '../Components/CatalogFilters'
import EveryGoods from "../Components/EveryGoods"
import vector from "../assets/vector.svg"
import {useLocation} from 'react-router-dom';
import API from "../API";
import Pagination from "react-js-pagination";

const CataloguePage = React.memo(() => {

    const [activeSortBy, changeActiveSortBy] = useState({
        variant: 'Popular',
        request_variant: null
    });
    const [filters_data, getFilters] = useState(() => null);
    const [categoryId, setCategoryId] = useState(() => null);
    const [goods, setGoods] = useState(() => null);
    const [activePage, setActivePage] = useState(() => 1);
    const [filtersMobile, changeFiltersMobile] = useState(null);
    const location = useLocation();

    useEffect(() => {
        ScrollToTop()
    }, []);

    let changeActivePagePagination = pageNumber => {
        setActivePage(pageNumber);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        let getLocationBrandHref = location.pathname.split('/').pop()
        setCategoryId(getLocationBrandHref);
        API.get('/products?subcategory_id=0&brands=' + getLocationBrandHref)
            .then(res => {
                setGoods(res.data)
            })

    }, [location.pathname]);

    return (
        <div className={'wrapper_main_page'}>
            {/*<div className={'preview_catalog_category'}>*/}
            {/*    <img src={backgroundCatalog} alt="backgroundCatalog"/>*/}
            {/*    <div className={'wrapper_info_catalog_preview'}>*/}
            {/*        <span className={'playfair_display_400 title_text'}>Диваны</span>*/}
            {/*        <span className={'open_sans_300 desc_text'}>*/}
            {/*            В меру мягкий, удобный и прочный диван прекрасно дополнит Вашу комнату.*/}
            {/*            К тому же, если выбрать подходящий размер, цвет и дизайн, то диван может*/}
            {/*            стать изюминкой вашей гостиной или спальни. Мы предлагаем Вам угловые,*/}
            {/*            раскладные, кожаные, бескаркасные диваны по доступным ценам.*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'wrapper_catalog_main_content'}>
                <div className={'wrapper_filters delete_mobili'}>
                    <PageTitle title={'Каталог'}/>
                </div>
                <div className={'wrapper_content'}>
                    <div className={'wrapper_filters mobili_version'}>
                        <div className={'wrapper_show_filter'}
                             onClick={() => changeFiltersMobile(!filtersMobile)}>
                            <span className={'roboto_400'}>Фильтр</span>
                            <img src={vector}
                                 className={filtersMobile ?
                                     'active-image' :
                                     ''}
                                 alt="vector"/>
                        </div>
                    </div>
                    {filtersMobile ? (
                        <CatalogFilters categoryId={categoryId}
                                        setGoods={setGoods}
                                        getFilters={getFilters}
                                        className={'delete_filter_lap'}
                                        activePage={activePage}
                                        activeSortBy={activeSortBy}
                                        filters_data={filters_data}/>
                    ) : null}
                    <div className={'wrapper_header_content'}>
                        <SortBy activeSortBy={activeSortBy}
                                changeActiveSortBy={changeActiveSortBy}/>
                        <div className={'wrapper_goods_length'}>
                            <span className={'playfair_display_400'}>
                                {goods?.total} goods
                            </span>
                        </div>
                    </div>
                    <div className={'wrapper_main_content'}>
                        {goods?.products.map(item => {
                            return (
                                <EveryGoods key={item.product_id}
                                            item={item}/>
                            )
                        })}
                    </div>
                    {goods?.total > 9?
                        <div className={'wrapper_pagination'}>
                            <Pagination
                                activePage={Number(activePage)}
                                itemsCountPerPage={27}
                                firstPageText={activePage >= 3 && goods.pages > 3 ? '1' : null}
                                prevPageText={<img src={vector} className={'prev_page_pagination'} alt="vector"/>}
                                nextPageText={<img src={vector} className={'next_page_pagination'} alt="vector"/>}
                                totalItemsCount={goods.total}
                                lastPageText={activePage <= goods.pages - 2 && goods.pages > 3 ? goods.pages.toString() : null}
                                pageRangeDisplayed={3}
                                onChange={pageNumber => changeActivePagePagination(pageNumber)}/>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
});

export default CataloguePage
