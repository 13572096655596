import React, {Component} from 'react';
import PageTitle from "../Components/PageTitle";
import GoogleApiWrapper from '../Components/GoogleMap'
import './styles/contacts.css';
import {ScrollToTop} from "../Constants/ScrollToTop";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'

export default class ContactsPage extends Component{
    state = {
        name: '',
        email: '',
        phone: '',
        message: '',
        checkbox: false,
        url: null
    };

    changeInputs = ({target}) => {
        this.setState({
            [target.name]: target.value
        })
    };

    componentDidMount() {
        ScrollToTop();
        this.setState({url: window.location.url})
    }

    contactsForm = () => {
        const { name, phone, message, checkbox, url } = this.state;
        const formSubmit = `mailto:request@vinteriors.store?subject=Vinteriors Request&body=${message} %0D%0A %0D%0A Имя: ${name} %0D%0A Телефон: ${phone}`
        return (
            <div className={'contacts-form'}>
                <Helmet>
                    <meta property="og:title" content={'Контакты — Vinteriors store.'}/>
                    <title>Контакты — Vinteriors store.</title>
                    <meta property="og:url" content={url}/>
                    <meta property="og:image" content={logo}/>
                    <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                    <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                    <meta property="twitter:title" content={'Контакты — Vinteriors store.'}/>
                    <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                    <meta property="twitter:image" content={logo}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                </Helmet>
                <div className={'w-50 order-1'}>
                    <div className={'form-block'}>
                        <h2>Напишите нам</h2>
                        <p>У вас есть вопросы? Напишите нам и мы ответим на них в течении 24 часов</p>
                        <form>
                            <input placeholder={'Ваше имя*'}
                                   name={'name'} onChange={this.changeInputs} value={name}
                                   required />
                            {/*<input placeholder={'Ваша почта*'}*/}
                            {/*       name={'email'} value={email} onChange={this.changeInputs}*/}
                            {/*       required />*/}
                            <input placeholder={'Ваш номер телефона*'}
                                   name={'phone'} onChange={this.changeInputs}
                                   value={phone} required />
                            <textarea placeholder={'Сообщение*'} name={'message'}
                                      rows={'6'} onChange={this.changeInputs}
                                      value={message}  required />
                            <a href={formSubmit}>Отправить</a>
                            <label htmlFor={'policy-terms'} className={`checkbox ${checkbox ? 'checkbox-active' : '' }`}
                                   onClick={() => {
                                       this.setState(state => ({
                                           checkbox: !state.checkbox
                                       }))
                                   }}>
                                <input name={'policy-terms'} value={checkbox} type={'checkbox'} />
                                <span>Я принимаю условия пользовательского соглашения</span>
                            </label>
                        </form>
                    </div>
                </div>
                <div className={'w-50 my-auto order-2'}>
                    <div className={'contacts-form-links'}>
                        <div className={'contacts-form-links-tablets'}>
                            {/*<div className={'w-50-tablets'}>*/}
                            {/*    <span>Номер телефона</span>*/}
                            {/*    <a href={'tel:+79160172103'}>+7 916 017 21 03</a>*/}
                            {/*</div>*/}
                            <div className={'w-50-tablets'}>
                                <span>Почта</span>
                                <a href={'mailto:request@vinteriors.store'}>request@vinteriors.store</a>
                            </div>
                        </div>
                        {/*<div className={'contacts-form-links-tablets'}>*/}
                        {/*    <div className={'w-50-tablets'}>*/}
                        {/*        <span>офис</span>*/}
                        {/*        <a href={'https://goo.gl/maps/xohoMH8qm2SMwd2x9'}*/}
                        {/*           target={'_blank'} rel="noopener noreferrer">*/}
                        {/*            Москва, Мичуринский проспект, Олимпийская деревня 1*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    };

    render() {

        return (
            <div className={'wrapper_main_page'}>

                <PageTitle title={'Contacts'}/>

                <GoogleApiWrapper />

                {this.contactsForm()}
            </div>
        )
    }

}
