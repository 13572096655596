import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function DeliveryPage() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Доставка — Vinteriors store.'}/>
                <title>Доставка — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="og:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:title" content={'Доставка — Vinteriors store.'}/>
                <meta property="twitter:description" content={'Что может быть лучше удобного дивана, чтобы скрасить вечер после тяжелого рабочего дня? И если вы выберете именно подходящий диван, вечер может стать еще лучше. Откиньтесь на спинку кресла и наслаждайтесь совершенством с любимым человеком или грейтесь в тени заходящего солнца в одиночестве.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <div className={'playfair_display_400'}>
                <h2 className={'title'}>Доставка</h2>
                <h3 className={'subtitle'}>Международная доставка</h3>
                <p className={'content'}>
                    {'Компания Vinteriors сотрудничает с избранными курьерами для доставки в многие страны мира. Мы осуществляем доставку по следующим направлениям:\n'}
                </p>
                <p className={'content'}>
                    Андорра, Австралия, Австрия, Азербайджан, Бахрейн, Бельгия, Болгария, Бразилия, Канада, Китай, Хорватия, Кипр, Чешская Республика, Дания, Египет, Финляндия, Франция, Германия, Гибралтар, Великобритания, Греция, Гернси, Гонконг, Венгрия, Исландия, Ирландия, Остров Мэн, Италия, Джерси, Ливан, Лихтенштейн, Люксембург, Монако, Нидерланды, Новая Зеландия, Норвегия, Объединенные Арабские Эмираты, Польша, Португалия, Катар, Саудовская Аравия, Сингапур, Соединенные Штаты, Турция, Швейцария, Швеция, Южная Корея.
                </p>
                <p className={'content'}>
                    Мы стремимся выполнить все заказы и, возможно, сможем доставить в ваш пункт назначения, даже если он не указан выше. Пожалуйста, свяжитесь с нами по адресу <a href={'mailto:request@vinteriors.store'}>request@vinteriors.store</a> чтобы обсудить вопросы доставки.</p>
                <p className={'content'}>Цена за доставку может отличаться в зависимости от страны. Стоимость доставки рассчитывается при оплате. Для предпросмотра цены доставки, просто добавьте товары в корзину, и выберите страну из списка.
                    Примите во внимание, что все товары отправляются без уплаты пошлины, исключая случаи договорённости об оплате вышеуказанных. Это значит, что все налоговые и пошлинные расходы оплачивает получатель. Если вы желаете, чтобы ваши товары доставили с оплатой пошлин - свяжитесь с нами <a href={'mailto:request@vinteriors.store'}>request@vinteriors.store</a></p>
                <p className={'content'}>'Определенные товары требуют особенной доставки. Для того, чтобы получить конкурентоспособный тариф на доставку для вас, нам необходимо вручную рассчитать ценовое предложение, которое будет выслано вам по электронной почте в течение 2 рабочих дней с момента получения вашего запроса. Это предложение останется в силе в течение 30 дней.'</p>
                <h2 className={'title'} style={{marginBottom: '15px'}}>Доставка заказа</h2>
                <p className={'content'}>{'Наша служба доставки доставляет заказы по Москве с 10:00 до 19:00 с понедельника по пятницу. Точный день и время согласовываются с консультантом, разместившим заказ. За 1 час до доставки перевозчик обязательно свяжется с покупателем.'}</p>
                <p className={'content'} style={{fontWeight: '400', fontSize: '18px', fontFamily: 'Playfair Display'}}>{'Доставка по России'}</p>
                <p className={'content'} style={{fontWeight: '400'}}>{'Доставка в города России осуществляется транспортной компанией.'}</p>
                <p className={'content'}>{'Служба доставки передаст заказ транспортной компании, которая организует доставку в любой регион России. Мы работаем с надежными и добросовестными транспортными компаниями, которые гарантируют целостность и безопасность Вашего заказа.'}</p>
            </div>
        </div>
    )
}
